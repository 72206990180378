import React  from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SectionsTitle from '../common/SectionsTitle'

export default function SimpleTab3({secTitle, secSubtitle,data}) {
    return (
        <section className="tabs-wrapper">
            <div className="container">
                <div className="row text-center">
                    <div className="col-12">
                        <SectionsTitle title={secTitle} subtitle={secSubtitle} />
                    </div>
                </div>


                <div className="tabs-content-wrap">
                    <Tabs>
                        <TabList className="tabs-nav">
                            {data?.map((item, index) => {
                                return <Tab key={index}>{item?.title}</Tab>
                            })}
                        </TabList>
                        {data?.map((tab, index) => {
                            return (
                                <TabPanel className="tabs-content grid bg-light mx-md-5 mx-auto " key={index}>
                                <div 
                                className='row row-col-4 justify-content-center'
                                //   style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",flexWrap:"wrap"}}
                                  >
                                  {tab?.partnerDetails?.map((item,index)=>{
                                    return (
                                      <div 
                                      className='bg-white'
                                      style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"column",width:"250px",margin:"10px",padding:"10px"}} key={index}>
                                        <img src={item?.avatar} alt='' style={{width:"50px",height:"50px",objectFit:"contain"}}/>
                                        <p style={{fontWeight:"600"}}>{item?.name}</p>
                                      </div>
                                    )
                                  })}
                                  </div>
                                </TabPanel>
                            )
                        })}
                    </Tabs>
                </div>
            </div>
        </section>
    )
}


// export default function SimpleTab3({secTitle, secSubtitle, tabsnav, tabslist}) {
//     return (
//         <section className="tabs-wrapper">
//             <div className="container">
//                 <div className="row text-center">
//                     <div className="col-12">
//                         <SectionsTitle title={secTitle} subtitle={secSubtitle} />
//                     </div>
//                 </div>


//                 <div className="tabs-content-wrap">
//                     <Tabs>
//                         <TabList className="tabs-nav">
//                             {tabsnav.map((item, index) => {
//                                 return <Tab key={index}>{item.title}</Tab>
//                             })}
//                         </TabList>
//                         {tabslist.map((tab, index) => {
//                             return (
//                                 <TabPanel className="tabs-content grid bg-light" key={index}>
//                                   <div 
//                                   className='d-flex justify-content-evenly align-items-center flex-wrap'
//                                 //   style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",flexWrap:"wrap"}}
//                                   >
//                                   {tab.map((item,index)=>{
//                                     return (
//                                       <div 
//                                       className='bg-white'
//                                       style={{display:"flex",justifyContent:"space-evenly",alignItems:"center",flexDirection:"column",width:"250px",margin:"10px",padding:"10px"}} key={index}>
//                                         <img src={item.logo} style={{width:"50%",height:"50%",objectFit:"contain"}}/>
//                                         <p style={{fontWeight:"600"}}>{item.name}</p>
//                                       </div>
//                                     )
//                                   })}
//                                   </div>
//                                 </TabPanel>
//                             )
//                         })}
//                     </Tabs>
//                 </div>
//             </div>
//         </section>
//     )
// }
