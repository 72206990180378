import React,{useState} from 'react'
import toast from 'react-hot-toast';
import subscription from '../../services/subscription';

const TakeFirstStep = () => {
const [email,setEmail]=useState("");
const [error,setError]=useState("");

const handleSubmit = async (e)=>{
    try {
        e.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim() || !emailPattern.test(email.trim())) {
          setError('Please enter a valid email address.');
          return;
        }

        const res=await subscription.save({email});
        if(res.success){
            toast.success(res.message);
            setEmail("")
        }
    } catch (error) {
        toast.error(error.message)
    }
}
  return (
    <>
         <section className="p-md-4" style={{ backgroundColor: "#ddeaf2"}}>
        <div className="container py-5">
          <div className="text-center">
            <div className="sec-heading w-md-75 m-auto" > 
              <h2 className="sec__title text-black">
                Take the first step towards career clearity
              </h2>
              <p
                style={{ fontSize: "13px" }}
                className="sec__meta m-auto text-black px-md-5 col-9"
              >
              These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.WE understand that the different career paths required different skill sets and our curriculam reflects thats
              </p>
            </div>
          </div>
          <div className="row col-10 m-auto my-4">
            <div className="col-md-9">
              <input
                type="email"
                required
                placeholder="Enter the Email"
                value={email}
                onChange={(e)=>{setEmail(e.target.value);setError("")}}
              />
              {error && <p className='text-danger'>{error}</p>}
            </div>
            <div className="col-md-3 d-flex d-md-block justify-content-center align-items-center">
              <button
                className="theme-button m-auto col-12 fw-semibold"
                style={{ backgroundColor: "#f2695c", textWrap: "nowrap" }}
                onClick={handleSubmit}
              >
                Submit Now
              </button>
            </div>
          </div>
        </div>
      </section>
      </>
  )
}

export default TakeFirstStep