import axios  from "./axios";

export default class domainService{
    static async list(){
        return await axios.get('domain/list')
    }

    static async details(slug){
        return await axios.get(`domain/details/${slug}`)
    }

}