import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'

const PursueTable = ({heading="",data}) => {

  const style={
    th:"p-4 fw-semibold align-middle text-start",
    td:"fs-5 fw-medium  align-middle  pe-5 py-5",
  }

  // const data=[
  //   {stream:"Clear Class XII with Science Stream with Maths",
  //     graduation:"Pursue B.Tech in Electrical Engineering / Electrical and Electronics Engineering / Electronics and Communication Engineering for 4 years",
  //     afterGraduation:"Pursue M.Tech for 2-3 years",
  //     afterPostGraduation:"Pursue Ph.D for 2-3 years"
  //   },
  //   {stream:"Clear Class XII with Science Stream with Maths",
  //     graduation:"Pursue B.Tech + M.Tech (Dual Degree)in Electrical Engineering for 5 years",
  //     afterGraduation:"",
  //     afterPostGraduation:"Pursue Ph.D for 2-3 years"
  //   },
  // ]

  return (
    <>
    <Row>
      <Col className='' >
          <h2 style={{fontWeight:"600"}} className='mb-4'>
            {/* How to Pursue a Career in Electrical and Electronics Engineering */}
            {heading}
            </h2>
            <div className=' overflow-x-auto'>
              {data?.length>0 && 
            <Table bordered className='mt-2'>
            <thead >
              <tr  className='fs-4'>
                <th style={{backgroundColor:"#F3F3F3"}}  className={style.th}></th>
                <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>Stream</th>
                <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>Graduation</th>
                <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>After Graduation</th>
                <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>After Post Graduation</th>
              </tr>
            </thead>
            <tbody className='fs-4'>
              {
                data?.map((el,idx)=>(
                  <tr key={idx}>
                  <td style={{transform:"rotate(180deg)",writingMode:"vertical-lr",fontWeight:"600"}} className='align-middle text-center'><span>Path {idx+1}</span></td>
                  <td  style={{color:"#707070"}} className={style.td}>{el?.stream}</td>
                  <td  style={{color:"#707070"}} className={style.td}>{el?.graduation}</td>
                  <td  style={{color:"#707070"}} className={style.td}>{el?.afterGraduation}</td>
                  <td  style={{color:"#707070"}} className={style.td}>{el?.afterPostGraduation}</td>
                </tr>
                ))
              }
              {/* <tr>
                <td><span>Path 1</span></td>
                <td>Clear Class XII with Science Stream with Maths</td>
                <td>Pursue B.Tech in Electrical Engineering / Electrical and Electronics Engineering / Electronics and Communication Engineering for 4 years</td>
                <td>Pursue M.Tech for 2-3 years</td>
                <td>Pursue Ph.D for 2-3 years</td>
              </tr> */}
            </tbody>
          </Table>
}
            </div>
          
      </Col>
    </Row>
    </>
  )
}

export default PursueTable