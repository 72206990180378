import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import SectionsTitle from "../components/common/SectionsTitle";
// import About1 from "../components/about/About1";
// import Divider from "../components/other/Divider";
// import About from "../components/about/About";
import CounterUp from "../sections/CounterUp";
import HeroSlider from "../components/sliders/HeroSlider";
// import SimpleTab from "../components/tabs/SimpleTab";
import SimpleTab3 from "../components/tabs/SimpleTab3";
// import Cta from "../sections/Cta";
// import Team from "../components/team/Team";
// import ChartArea from "../sections/ChartArea";
import Clients from "../sections/Clients";
// import Pricing from "../sections/Pricing";
// import CtaForm from "../components/other/CtaForm";
import Blog from "../components/blog/Blog";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import Footer from "../components/other/Footer";
import sectiondata from "../store/store";
// import ServiceSection1 from "../sections/services/ServiceSection1";
import TestimonialSection from "../sections/TestimonialSections";
import Service from "../components/services/Services";

// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import mainCategoryService from "../services/mainCategory";
// import { Col } from "react-bootstrap";
import homeService from "../services/home";
import TakeFirstStep from "../components/home/TakeFirstStep";
import BookModal from "../components/BookModal";
import MindlerWay from "../components/home/MindlerWay";
import OurPrograms from "../components/home/OurPrograms";



export default function Home() {
  const [data,setData]=useState({
    institution:[],
    skill:[],
    job:[],
  });
    const [category,setCategory]=useState([]);
    const [showModal,setShowModal]=useState(false);

    


  useEffect(()=>{
    mainCategoryService.list().then(res=>{
      if(res.success){
       setCategory(res.data)
      }
  }
    ).catch(err=>{throw err});
  },[]);


  useEffect(()=>{
      ;(async ()=>{
        try {
            const {data:institution}=await homeService.institution();
            const {data:skill}   = await homeService.skill();
            const {data:job}   =  await homeService.job();
               const initialState={institution,skill,job};
               setData(initialState);

        } catch (error) {
          console.log(error)
        }
      })();
       
  },[])

  
  return (
    <>
    <BookModal isShow={showModal} setIsShow={setShowModal}/>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Hero Slider */}
      <HeroSlider slideItems={sectiondata.Sliders.heroSlider1} />

      {/* <Divider /> */}

      {/* Our Programs */}
      <OurPrograms category={category}/>



      {/* About 1*/}
      {/* <About1 /> */}

      {/* THe Mindler Way */}
      <MindlerWay setShowModal={setShowModal}/>


      {/* Why Choose Us */}
      {/* <SimpleTab secTitle={sectiondata.Tabdata.tab1.secTitle} secSubtitle={sectiondata.Tabdata.tab1.secSubtitle} tabslist={sectiondata.Tabdata.tab1.items} tabsnav={sectiondata.Tabdata.tab1.tabsnav} /> */}

      {/* Take the first Steps towards career clearity */}
 <TakeFirstStep/>

      {/* Team */}
      {/* <Team
                secTitle={sectiondata.Teammemberdata.secTitle}
                secSubtitle={sectiondata.Teammemberdata.secSubtitle}
                teammembers={sectiondata.Teammemberdata.teammembers} /> */}
      {/* <Divider /> */}

      {/* ChartArea */}
      {/* <ChartArea
                secTitle={sectiondata.Chartareadata.secTitle}
                secSubtitle={sectiondata.Chartareadata.secSubtitle}
                title1={sectiondata.Chartareadata.title1}
                title2={sectiondata.Chartareadata.title2}
                content1={sectiondata.Chartareadata.content1}
                content2={sectiondata.Chartareadata.content2}
                buttontext={sectiondata.Chartareadata.buttontext}
                buttonurl={sectiondata.Chartareadata.buttonurl} /> */}

      {/* Why Join Us */}
      <div className="h-service">
        <div className="container">
          <div className="row text-center">
            <SectionsTitle
              title={sectiondata.Services.Service1.secTitle}
              subtitle={sectiondata.Services.Service1.secSubtitle}
            />
          </div>
        </div>
        <div className="container">
          <div className="services-items-grid">
            <Service sLists={sectiondata.Services.Service1.serviceLists} />
          </div>
        </div>
      </div>

      {/* Say About Us */}
      {/* Testimonial*/}
      <TestimonialSection
        secTitle={sectiondata.Testimonialdata.secTitle}
        secSubtitle={sectiondata.Testimonialdata.secSubtitle}
        sliders={sectiondata.Sliders.testimonial}
        imgshapes={sectiondata.Testimonialdata.imagesshape}
      />

      {/* Counter Up */}
      <CounterUp
        secTitle={sectiondata.CounterUp.secTitle}
        secSubtitle={sectiondata.CounterUp.secSubtitle}
        counteritems={sectiondata.CounterUp.items}
        counterbottomtitle={sectiondata.CounterUp.counterbottomtitle}
        counterbottombtn={sectiondata.CounterUp.counterbottombtn}
        counterbottombtnurl={sectiondata.CounterUp.counterbottombtnurl}
      />

      {/* Our Partner Institutions */}
      <SimpleTab3
        secTitle={sectiondata.Tabdata.tab6.secTitle}
        secSubtitle={sectiondata.Tabdata.tab6.secSubtitle}
        // tabslist={sectiondata.Tabdata.tab6.items}
        // tabsnav={sectiondata.Tabdata.tab6.tabsnav}
        data={data?.institution}
      />

      {/* Skill Up Programme */}
      <SimpleTab3
        secTitle={sectiondata.Tabdata.tab5.secTitle}
        secSubtitle={sectiondata.Tabdata.tab5.secSubtitle}
        // tabslist={sectiondata.Tabdata.tab5.items}
        // tabsnav={sectiondata.Tabdata.tab5.tabsnav}
        data={data?.skill}
      />

      {/* Job Ready Programme */} 
      <div style={{backgroundColor:"#f6f8f9",paddingBottom:"50px"}}>
      <SimpleTab3
        secTitle={sectiondata.Tabdata.tab4.secTitle}
        secSubtitle={sectiondata.Tabdata.tab4.secSubtitle}
        // tabslist={sectiondata.Tabdata.tab4.items}
        // tabsnav={sectiondata.Tabdata.tab4.tabsnav}
        data={data?.job}
      />
            </div>


      {/* Clients Logo */}
      <div className="container">
        <div className="row text-center">
          <div className="col-12">
            {/* <SectionsTitle title={"Our Mentor and Mentee works at"} subtitle={"These programs are 100% industry led and mentored by Industry Mentor.You can choose from full length Tech and Non tech Programs"} /> */}
            <div style={{ margin: "50px" }} className="sec-heading">
              <h2 className="sec__title">{"Our Mentor and Mentee works at"}</h2>
              <p className="sec__meta">
                {
                  "These programs are 100% industry led and mentored by Industry Mentor.You can choose from full length Tech and Non tech Programs"
                }
              </p>
            </div>
          </div>
        </div>

        <Clients sliders={sectiondata.Sliders.clientlogos} />
      </div>

      {/* Latest News */}
      <div className="h-blog-wrap">
        <div className="container">
          <div className="row">
            <div className="col-8">
              {/* <SectionsTitle
                title="Our Recent News."
                subtitle="Latest From The Blog"
              /> */}

           <div className="sec-heading">
                   <h2  className="sec__title"> Our Recent Blogs. </h2>
                    <p className="sec__meta">Latest From The Blog</p>
            </div>
            </div>
            <div className="col-4 text-right">
              <Link to="/blog-grid" className="theme-button">
                View All Posts <FiChevronRight className="icon" />
              </Link>
            </div>
          </div>
        </div>
        <Blog />
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
