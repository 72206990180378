import React from "react";
import { withBlogConsumer } from "../../context";
import BlogFeatures from "./BlogFeatures";
// import blogService from '../../services/blog'

function BlogsContainer({blogList}) {
   

    return (
        <>
            {blogList.map((item, index) => {
                return <BlogFeatures key={index} blog={item} />;
            })}

            
        </>
    );
}
export default withBlogConsumer(BlogsContainer);