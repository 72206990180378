import axios from "./axios";

export default class categoryService {
  static async list() {
    return await axios.get("category/list");
  }

  static async detail(id) {
    return await axios.get("category/detail/" + id);
  }
}
