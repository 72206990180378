import axios  from "./axios";

export default class counsellorService{
    static async list(query){
       
        return await axios.get(`/counselor/list`,{params:query})
    }

    static async details(slug){
        return await axios.get(`/counselor/details/${slug}`)
    }

}
