import React,{useState}  from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiPhoneCall } from 'react-icons/fi'
import { MdMailOutline } from 'react-icons/md'
import contactUsService from '../../services/contactUs'
import toast from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner'


export default function CtaForm() {
    const initialState={
        name:"",
        email:"",
        phone:"",
        subject:"",
        message:"",
    }
const [isLoading,setIsLoading]=useState(false)
    const [formData,setFormData]=useState(initialState);
    const [error,setError]=useState(initialState)

    const handleChange=(e)=>{
        const {name,value}=e.target;
        setFormData({
            ...formData,
            [name]:value
        })

        setError({
            ...error,
            [name]:""
        })
    }


    const handleSubmit=async (e)=>{
        try {
            e.preventDefault();
            setIsLoading(true)
            const response= await contactUsService.save(formData);
            console.log("response",response)
              if(response.success){
                //   alert(response.message);
                toast.success(response.message)
                  setFormData(initialState);
              }else{
                toast.error(response.message)
              }
        } catch (error) {
           console.log(error);
           toast.error(error.message)
        }finally{
            setIsLoading(false)
        }
     
    }
    return (
        <section className="ctaform-wrapper">
            <div className="container">
                <div className="ctaform-grid">
                    <div className="ctaform-left">
                        <SectionsTitle title="Get Advice From Our Professionals." subtitle="Call To Action" />
                        <div className="ctaform-contact-info-grid text-center">
                            <div className="item">
                                <FiPhoneCall className="icon" />
                                <h2 className="item-title">Direct Line Numbers</h2>
                                <p>+11 (0) 123 456 7890 / +11 (0) 593 458 2394</p>
                            </div>
                            <div className="item">
                                <MdMailOutline className="icon" />
                                <h2 className="item-title">Our Email</h2>
                                <p>info@minzel.com/ example@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="ctaform-right">
                        <form>
                            <div className="form-grid">
                                <input type="text" name="name" onChange={handleChange} placeholder="Name" />
                                <input type="EMAIL" name="email" onChange={handleChange} placeholder="Email" />
                            </div>
                            <div className="form-grid">
                            <input type="text" name='subject'onChange={handleChange} placeholder="Subject" />
                                <input type="Number" name="phone" onChange={handleChange} placeholder="Phone Number" />
                            </div>

                            <textarea id="textarea" placeholder="Message" name='message'onChange={handleChange} ></textarea>
                            <button type="button" onClick={handleSubmit} value="Send Us Message" className="theme-button">{isLoading?
                                <>
                            <ColorRing
                                visible={true}
                                height="40"
                                width="40"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={["white","white","white","white","white"]}
                            />
                            loading...
                            </> :<>Send Us Message</>}</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
