import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/other/Footer";
// import Breadcrumb from "../components/common/Breadcrumb";
import careerPathService from "../services/careerPath";
import { Link, useLocation } from "react-router-dom";

const CareerPathCategory = () => {
  const [careerCategory, setCareerCategory] = useState([]);
  const location = useLocation();

  // useEffect(() => {
  //   if (location && location.state) {
  //     setCareerSubCategory(location.state.careerPathDetails);
  //   }
  // }, []);

  useEffect(()=>{
    if(location && location.state){
      careerPathService.category(location.state).then(res=>{
        if(res.success){
          setCareerCategory(res.data)
        }
      })
      .catch(err=>{throw err});
    }

  },[])

  return (
    <div className="bg-light">
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      {/* <Breadcrumb title="Career Path" /> */}

      <section className="breadcrumb-wrapper text-center">
        <div className="container">
          <div className="breadcrumb-content">
            <h2>{location?.state?.title}</h2>
            <h6 className="text-white">{location?.state?.subTitle}</h6>
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>{location?.state?.title}</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="container my-5">

      <div  style={{color:"#233D63"}} className="m-auto text-center my-3">
          <h1 style={{fontWeight:"700"}} className="mb-3">Diploma Courses After 10<sub style={{color:"#233D63"}} className="align-top">th</sub></h1>
          <strong  style={{color:"#233D63",fontWeight:"600"}} >These programs are 100% industry-led and mentored by Industry Mentor. You can choose from full-length Tech and Non-tech Programs.</strong>
        </div>

        <div className="row row-cols-md-4 g-4 my-2">
          {careerCategory.map((item, index) => {
            return (
              //   <Link to={`/career-detail/${item?.slug}`} key={index}>
              //   <div className="card bg-white p-0 border-0">
              //     <img src={item?.image} className=" img-fluid rounded-4" alt="..." />
              //     <div className="card-body">
              //       <h6 className="card-text fw-semibold">
              //        {item?.heading}
              //       </h6>
              //     </div>
              //   </div>
              // </Link>

              <Link
                to={`/career-detail/${item?.slug}`}
                key={index}
              >
                <div className="card bg-white p-3 border-0 shadow rounded-4">
                  {/* <img
                    src={item?.image}
                    className="img-fluid rounded-3"
                    alt="..."
                  /> */}
                  <div className="card-body mb-4 text-center">
                    <h2 className="card-title fw-bold">{item?.heading}</h2>
                    {/* <h5 className="card-text fw-semibold">
                      ({item?.subTitle})
                    </h5> */}
                  </div>
                  <button
                    style={{ backgroundColor: "rgb(242, 105, 92)" }}
                    className="theme-button col-6 m-auto"
                  >
                    <img
                      src="./images/right-arrow.svg"
                      className="w-75 text-white"
                      alt=""
                    />
                  </button>
                </div>
              </Link>
            );
          })}

          {/* 
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>

        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>

          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>

          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>  */}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default CareerPathCategory;
