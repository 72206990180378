import React, { useState }  from 'react'
import SectionsTitle from '../common/SectionsTitle'
import { FiChevronRight } from 'react-icons/fi'
import ContactSidebar from './ContactSidebar'
import Maps from '../contact/Maps'
import CtaNewsletter from "../../sections/CtaNewsletter";
import sectiondata from "../../store/store";
import SocialProfile from "../widgets/SocialProfile";
import contactUsService from '../../services/contactUs'
import {toast} from 'react-hot-toast'
import { ColorRing } from 'react-loader-spinner'

export default function ContactDetails() {
    const initialState={
        name:"",
        email:"",
        phone:"",
        subject:"",
        message:"",
    }
const [isLoading,setIsLoading]=useState(false)
    const [formData,setFormData]=useState(initialState);
    const [error,setError]=useState(initialState)

    const handleChange=(e)=>{
        const {name,value}=e.target;
        setFormData({
            ...formData,
            [name]:value
        })

        setError({
            ...error,
            [name]:""
        })
    }


    const handleSubmit=async (e)=>{
        try {
            e.preventDefault();
            setIsLoading(true)
            const response= await contactUsService.save(formData);
            console.log("response",response)
              if(response.success){
                //   alert(response.message);
                toast.success(response.message)
                  setFormData(initialState);
              }else{
                toast.error(response.message)
              }
        } catch (error) {
           console.log(error);
           toast.error(error.message)
        }finally{
            setIsLoading(false)
        }
     
    }

    return (
        <section className="contact-form-wrapper">
            <div className="container">
                <div className="contact-form-grid d-grid">
                    <div className="left">
                        <SectionsTitle title={sectiondata.contactdata.secTitle} subtitle={sectiondata.contactdata.secSubtitle} />
                        <p>{sectiondata.contactdata.content}</p>
                        <div className="sidebar-social-profile">
                        <SocialProfile lists={sectiondata.contactdata.socials} />
                        </div>
                    </div>
                    <div className="right">
                        <form>
                            <div className="form-grid d-grid">
                                <div className="left">
                                    <label htmlFor="name">Your Name <sup>*</sup></label>
                                    <input type="text" id="name" name='name' placeholder="Full Name" onChange={handleChange} required />
                                </div>
                                <div className="right">
                                    <label htmlFor="email">Your Email <sup>*</sup></label>
                                    <input type="email" id="email" name='email' placeholder="Email Address" onChange={handleChange} required />
                                </div>
                                <div className="left">
                                    <label htmlFor="number">Your Number <sup>*</sup></label>
                                    <input type="text" id="number" name="phone" placeholder="Your Phone" onChange={handleChange} required />
                                </div>
                                <div className="right">
                                    <label htmlFor="subject">Subject <sup>*</sup></label>
                                    <input type="text" id="subject" name='subject' placeholder="Subject" onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="form-textarea">
                                <label htmlFor="message">Your Message <sup>*</sup></label>
                                <textarea id="message" name='message' placeholder="Write Message" onChange={handleChange}></textarea>
                            </div>
                            <button type="submit" className="theme-button" onClick={handleSubmit}>{isLoading?
                                <><ColorRing
  visible={true}
  height="40"
  width="40"
  ariaLabel="color-ring-loading"
  wrapperStyle={{}}
  wrapperClass="color-ring-wrapper"
  colors={["white","white","white","white","white"]}
  />
  loading...
  </> :<>Send Us Message <FiChevronRight className="icon" /></>}</button>
                        </form>
                    </div>
                </div>

                <div className="contact-form-map-grid d-grid">
                    <div className="left">
                        <Maps />
                    </div>
                    <div className="right">
                        <ContactSidebar />
                    </div>
                </div>

                <CtaNewsletter title={sectiondata.ctadata.cta1.title} content={sectiondata.ctadata.cta1.content} />
            </div>
        </section>
    )
}
