import React,{useState,useEffect} from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import domainService from "../services/domain";
import industryService from "../services/industry";
import mentorService from "../services/mentor";
import {BeatLoader} from "react-spinners"
import toast from "react-hot-toast";
// import { toast } from "react-toastify"



const BecomeMentor = () => {
  const initialState={
    name:"",
    email:"",
    phone:"",
    LinkedIn:"",
    gender:"",
    dob:"",
    address:"",
    // city:"",
    // state:"",
    interest:[],
    mentor:[],
    industry:"",
    other:"",
    domain1:"",
    domain2:"",
    domain3:"",
    college:"",
    degree:"",
    year:"",
    postCollege:"",
    postDegree:"",
    postYear:"",
    yourself:"",
    about:"",
  }

  const [isLoading,setIsLoading]=useState(true);
  const [formData,setFormData]=useState(initialState);
  const [error,setError]=useState(initialState);
  const [dropdowns,setDropdowns]=useState({
     industryList:[],
     domainList:[]
  });

  const handleChange=(e)=>{
    console.log("e.target",e.target.checked);
    const {type,name,value,checked}=e.target;

    if(type==="checkbox"){
         if(name==="interest"){
               if(checked){
                const interest = formData.interest
                interest.push(value)
                setFormData(prev=>{return {...prev,interest}})
               }else{
               const interest= formData.interest.filter((item)=>item!==value)
               setFormData(prev=>{return {...prev,interest}})
               }
         }

         if(name==="mentor"){
          if(checked){
            const mentor = formData.mentor
            mentor.push(value)
            setFormData(prev=>{return {...prev,mentor}})
           }else{
           const mentor= formData.mentor.filter((item)=>item!==value)
           setFormData(prev=>{return {...prev,mentor}})
           }
         }
    }else{
      setFormData(prev=>{return {...prev,[name]:value}})
    }

    setError(prev=>{return {...prev,[name]:""}})
    console.log("formData",formData);
  }


  const validateForm = (formData) => {
    let isValid = true;
    let errors = {};
  
    // Name validation
    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    } else if (!/^[A-Za-z ]+$/.test(formData.name)) {
      errors.name = "Name can only contain alphabets";
      isValid = false;
    }
  
    // Email validation
    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }
  
    // Phone validation
    if (!formData.phone) {
      errors.phone = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number should be 10 digits";
      isValid = false;
    }
  
    // LinkedIn validation (assuming LinkedIn is optional)

    if (!formData.LinkedIn) {
      errors.LinkedIn = "LinkedIn URL is required";
      isValid = false;
    }

    // if (formData.LinkedIn && !/^https?:\/\/(www\.)?linkedin\.com\/.*$/.test(formData.LinkedIn)) {
    //   errors.LinkedIn = "LinkedIn URL is invalid";
    //   isValid = false;
    // }


  
    // Gender validation (assuming gender is required and must be one of specified values)
    if (!formData.gender) {
      errors.gender = "Gender is required";
      isValid = false;
    }
    // Date of Birth validation (assuming it must be a valid date in the past)
    if (!formData.dob) {
      errors.dob = "Date of Birth is required";
      isValid = false;
    } else {
      const dobDate = new Date(formData.dob);
      const currentDate = new Date();
      if (isNaN(dobDate) || dobDate >= currentDate) {
        errors.dob = "Date of Birth should be a valid date in the past";
        isValid = false;
      }
    }
  
    // Address validation (assuming it's required and must be at least 5 characters)
    if (!formData.address || formData.address.length < 5) {
      errors.address = "Address is required and should be at least 5 characters long";
      isValid = false;
    }

    // if (!formData.city) {
    //   errors.city = "City is required";
    //   isValid = false;
    // }

    // if (!formData.state) {
    //   errors.state = "State is required";
    //   isValid = false;
    // }
  
    // Interest validation (assuming it's required)
    if (!formData.interest) {
      errors.interest = "Interest is required";
      isValid = false;
    }
  
  
    // Industry validation (assuming it's required and must be one of specified values)
    if (!formData.industry) {
      errors.industry = "Industry is required";
      isValid = false;
    } 

    if (!formData.mentor) {
      errors.mentor = "Mentor is required";
      isValid = false;
    } 

    if (!formData.domain1) {
      errors.domain1 = "Domain First Preference is required";
      isValid = false;
    } 

    if (!formData.domain2) {
      errors.domain2 = "Domain second preference is required";
      isValid = false;
    } 

    if (!formData.domain3) {
      errors.domain3 = "Domain thors preference is required";
      isValid = false;
    } 

    if (!formData.mentor) {
      errors.mentor = "Mentor is required";
      isValid = false;
    } 

    if(!formData.degree){
      errors.degree="Graduation degree is required"
      isValid=false;
    }

    if(!formData.college){
      errors.college="Graduation College name is required"
      isValid=false;
    }

    if(!formData.year){
      errors.year="Graduation year is required"
      isValid=false;
    }

    if(!formData.postDegree){
      errors.postDegree="Post Graduation degree is required"
      isValid=false;
    }

    if(!formData.postCollege){
      errors.postCollege="Post Graduation college name is required"
      isValid=false;
    }

    if(!formData.postYear){
      errors.postYear="Post Graduation year is required"
      isValid=false;
    }

    if(!formData.yourself){
      errors.yourself="Short bio about yourself is required"
      isValid=false;
    }

    if(!formData.about){
      errors.about="How do you know about is required"
      isValid=false;
    }

  
    return { isValid, errors };
  }
  


  const handleSubmit = async (e)=>{
    try {
      e.preventDefault();
      setIsLoading(false);

      const { isValid, errors } = validateForm(formData);

      if (!isValid) {
        setError(errors);
        return;
      }

      const res= await mentorService.save(formData)
      if(res.success){
        toast.success(res.message);
        setFormData(initialState);
      }

    } catch (error) {
      toast.error(error.message);
    }finally{
      setIsLoading(false)
    }
   


  }

  useEffect(()=>{
   (async ()=>{
    try{
      setIsLoading(true)
  //Fetch the industries list when component mounts
  const {data:industries}=await industryService.list();

  //Fetch the domain list when component mounts
   const {data:domains}=await domainService.list();
   setDropdowns({industryList:industries,domainList:domains});
    }catch(err){
    //  toast.error(err.message)
    }finally{
      setIsLoading(false)
    }
   
   })()

  },[]);

useEffect(()=>{
window.scrollTo(0,0);
},[]);

  return (
    <main>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title="Become a Mentor" />

      <section className="py-5">
        <div className="container d-flex flex-column flex-md-row align-items-end m-auto ">
          <div className="col-6">
            <h2>Become a Mentor</h2>
            <h4 style={{ color: "#F2695C" }} className="fs-5 fw-bolder my-4">
              {" "}
              Sub Title
            </h4>
            <p className="fw-medium">
              These programs are carefully crafted to meet the specific demands
              of both tech and non-tech industries.We understand that different
              career paths required different skillset and our cirrculam
              reflects that
            </p>
            <p className="fw-medium">
              These programs are carefully crafted to meet the specific demands
              of both tech and non-tech industries.We understand that different
              career paths required different skillset and our cirrculam
              reflects that
            </p>
          </div>
          <div className="">
            <img className="img-fluid" src="./images/Group 221.png" alt="" />
          </div>
        </div>
      </section>

      <section className="bg-light py-5">
        <div className="container">
          <h2>Enter your Details Below</h2>
          <form className="p-2">
            <div className="row row-cols-md-3">
              <div class="mb-3">
                <label
                  for="name"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Your Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Full Name"
                  className={error.name ?`border-danger`:""}
                />
                {error.name && <p className="text-danger ps-2">{error.name}</p>}
              </div>
              <div class="mb-3">
                <label
                  for="phone"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Phone Number<span className="text-danger">*</span>
                </label>
                <input
                  type="Number"
                  id="phone"
                  name="phone"
                  minLength={10}
                  maxLength={10}
                  min={0}
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  className={error.phone ?`border-danger`:""}
                  />
                  {error.phone && <p className="text-danger ps-2">{error.phone}</p>}
              </div>
              <div class="mb-3">
                <label
                  for="email"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className={error.email ?`border-danger`:""}
                  />
                  {error.email && <p className="text-danger ps-2">{error.email}</p>}
              </div>

              <div class="mb-3">
                <label
                  for="profile"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label"
                >
                  LinkedIn Profile Link<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="profile"
                  name="LinkedIn"
                  placeholder="LinkedIn Profile Link"
                  value={formData.LinkedIn}
                  onChange={handleChange}
                  className={error.LinkedIn ?`border-danger`:""}
                  />
                  {error.LinkedIn && <p className="text-danger ps-2">{error.LinkedIn}</p>}
              </div>

              <div class="mb-3">
                <label
                  for="gender"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label"
                >
                  Gender<span className="text-danger">*</span>
                </label>
                <select name="gender" id="gender" className={`w-100 ${error.gender ?"border-danger":""}`} value={formData.gender} onChange={handleChange}>
                  <option value={""} disabled>
                    Select Your Gender
                  </option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                  <option value={"other"}>Other</option>
                </select>
                {error.gender && <p className="text-danger ps-2">{error.gender}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="dob"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Date Of Birth<span className="text-danger">*</span>
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  placeholder="Date of Birth"
                  value={formData.dob}
                  onChange={handleChange}
                  className={error.dob ?`border-danger`:""}
                  />
                  {error.dob && <p className="text-danger ps-2">{error.dob}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="address"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Address<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                  className={error.address ?`border-danger`:""}
                  />
                  {error.address && <p className="text-danger ps-2">{error.address}</p>}
              </div>
{/* 
              <div className="mb-3">
                <label
                  for="city"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  City<span className="text-danger">*</span>
                </label>
                <input type="text" id="city" name="city" placeholder="City" value={formData.city} onChange={handleChange} 
                  className={error.city ?`border-danger`:""}
                  />
                  {error.city && <p className="text-danger ps-2">{error.city}</p>}
              </div> */}

              {/* <div className="mb-3">
                <label
                  for="state"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  State<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  placeholder="State"
                  className={error.state ?`border-danger`:""}
                  value={formData.state}
                  onChange={handleChange}
                  />
                  {error.state && <p className="text-danger ps-2">{error.state}</p>}
              </div> */}
            </div>

            <div className="row row-cols-md-3">
              <div className="mb-3">
                <label
                  for="interest"
                  style={{ fontSize: "1.1rem", fontWeight: "600" }}
                  className="form-label"
                >
                  Choose Your Interest<span className="text-danger">*</span>
                </label>
                <div>
                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="Entrepreneurship"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Entrepreneurship"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="Entrepreneurship"
                    >
                      Entrepreneurship
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="BusinessCoach"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Business-Coach"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="BusinessCoach"
                    >
                      Business Coach
                    </label>
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="IndustryMentorship"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Industry-Mentorship"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="IndustryMentorship"
                    >
                     Industry Mentorship
                    </label>
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="CareerCounselling"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Career-Counselling"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="CareerCounselling"
                    >
                     Career Counselling
                    </label>
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="CreateWebinarsWorkshopsetc"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Create-Webinars"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="CreateWebinarsWorkshopsetc"
                    >
                     Create Webinars, Workshops etc.
                    </label>
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="Others"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Other"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="Others"
                    >
                     Others
                    </label>
                  </div>
                </div>
              {error.interest && <p className="ps-2 text-danger">{error.interest}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="whoDoYouWantToMentor"
                  style={{ fontSize: "1.1rem", fontWeight: "600" }}
                  className="form-label"
                >
                  Who do You Want To Mentor<span className="text-danger">*</span>
                </label>
                <div>
                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="Students"
                      name="mentor"
                      className="w-auto d-inline m-2"
                      value={"Students"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="Students"
                    >
                      Students
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="Entrepreneurs"
                      name="mentor"
                      className="w-auto d-inline m-2"
                      value={"Entrepreneurs"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="Entrepreneurs"
                    >
                      Entrepreneurs
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    <input
                      type="checkbox"
                      id="Professionals"
                      name="mentor"
                      className="w-auto d-inline m-2"
                      value={"Professionals"}
                      onChange={handleChange}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="Professionals"
                    >
                     Professionals
                    </label>
                  </div>
{/* 
                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="CareerCounselling"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Career Counselling"}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="CareerCounselling"
                    >
                     Career Counselling
                    </label>
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="CreateWebinarsWorkshopsetc"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Create Webinars, Workshops etc."}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="CreateWebinarsWorkshopsetc"
                    >
                     Create Webinars, Workshops etc.
                    </label>
                  </div>

                  <div className="d-flex  align-items-center">
                    <input
                      type="checkbox"
                      id="Others"
                      name="interest"
                      className="w-auto d-inline m-2"
                      value={"Others"}
                    />
                    <label
                      style={{ fontSize: ".9rem", fontWeight: "600" }}
                      className="form-label m-2"
                      htmlFor="Others"
                    >
                     Others
                    </label>
                  </div> */}
                </div>
              {error.mentor && <p className="ps-2 text-danger">{error.mentor}</p>}
              </div>
            </div>

            <div className="row row-cols-md-3">
              <div className="mb-3">
                <label
                  for="industry"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label pb-md-4  pb-lg-0 "
                >
                  Select Your Industry<span className="text-danger">*</span>
                </label>
              <select id="industry" name="industry" className="w-100" value={formData.industry} onChange={handleChange}>
                <option value={""} disabled selected>Select Your Industry</option>
                {
                dropdowns?.industryList?.map((item,index)=>{return (
                  <option key={index} value={item?.name}>{item?.name}</option>
                )})}
              </select>
              {error.industry && <p className="ps-2 text-danger">{error.industry}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="otherIndustry"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label pb-md-4  pb-lg-0"
                >
                  If Other Please specify<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="otherIndustry"
                  name="other"
                  value={formData.other}
                  onChange={handleChange}
                  placeholder="If Other Please specify"
                />
              </div>

              <div className="mb-3">
                <label
                  for="1stpreference"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Choose Domain Expertise (1st preference)<span className="text-danger">*</span>
                </label>
              <select id="1stpreference" name="domain1" className="w-100" value={formData.domain1} onChange={handleChange}>
                <option value={""} disabled selected>Choose Domain Expertise (1st preference)</option>
                {
                dropdowns?.domainList?.map((item,index)=>{
                  return (
                    <option key={index} value={item?.name}>{item?.name}</option>
                  )
                })}
              </select>
              {error.domain1 && <p className="ps-2 text-danger">{error.domain1}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="2ndpreference"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Choose Domain Expertise (2nd preference)<span className="text-danger">*</span>
                </label>
              <select id="2ndpreference" name="domain2" className="w-100" value={formData.domain2} onChange={handleChange}>
                <option value={""} disabled >Choose Domain Expertise (2nd preference)</option>
                {dropdowns?.domainList?.map((item,index)=>{
                  return (
                    <option key={index} value={item?.name}>{item?.name}</option>
                  )
                })}
              </select>
              {error.domain2 && <p className="ps-2 text-danger">{error.domain2}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="3rdpreference"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label"
                >
                  Choose Domain Expertise (3rd preference)<span className="text-danger">*</span>
                </label>
              <select id="3rdpreference" name="domain3" className="w-100" value={formData.domain3} onChange={handleChange}>
                <option value={""} disabled >Choose Domain Expertise (3rd preference)</option>
                {
                  dropdowns?.domainList?.map((item,index)=>{
                    return (
                      <option key={index} value={item?.name}>{item?.name}</option>
                    )
                  })
                }
              </select>
              {error.domain3 && <p className="ps-2 text-danger">{error.domain3}</p>}
              </div>
            </div>

            <div className="row row-cols-md-3">
            <label
                  for="graduation"
                  style={{ fontSize: "1.1rem", fontWeight: "600" }}
                  className="form-label col-12 mb-3"
                >
                  Graduation Mandatory<span className="text-danger">*</span>
                </label>


                <div className="mb-3">
                <label
                  for="universityName"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  University/College<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="universityName"
                  name="college"
                  placeholder="University/College"
                  value={formData.college}
                  onChange={handleChange}
                />
              {error.college && <p className="ps-2 text-danger">{error.college}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="degree"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Degree<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="degree"
                  name="degree"
                  placeholder="Degree"
                  value={formData.degree}
                  onChange={handleChange}
                />
              {error.degree && <p className="ps-2 text-danger">{error.degree}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="passoutYear"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Passout Year<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="passoutYear"
                  name="year"
                  placeholder="PassoutYear"
                  value={formData.year}
                  onChange={handleChange}
                />
              {error.year && <p className="ps-2 text-danger">{error.year}</p>}
              </div>

            </div>

            <div className="row row-cols-md-3">
            <label
                  for="graduation"
                  style={{ fontSize: "1.1rem", fontWeight: "600" }}
                  className="form-label col-12 mb-3"
                >
                 Post Graduation Mandatory<span className="text-danger">*</span>
                </label>


                <div className="mb-3">
                <label
                  for="universityName"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  University/College<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="universityName"
                  name="postCollege"
                  placeholder="University/College"
                  value={formData.postCollege}
                  onChange={handleChange}
                />
              {error.postCollege && <p className="ps-2 text-danger">{error.postCollege}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="degree"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Degree<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="degree"
                  name="postDegree"
                  placeholder="Degree"
                  value={formData.postDegree}
                  onChange={handleChange}
                />
              {error.postDegree && <p className="ps-2 text-danger">{error.postDegree}</p>}
              </div>

              <div className="mb-3">
                <label
                  for="passoutYear"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label  "
                >
                  Passout Year<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  id="passoutYear"
                  name="postYear"
                  placeholder="PassoutYear"
                  value={formData.postYear}
                  onChange={handleChange}
                />
              {error.postYear && <p className="ps-2 text-danger">{error.postYear}</p>}
              </div>

            </div>

            <div className="row row-cols-md-2">
              <div class="mb-3">
              <label
                  htmlFor="aboutYourself"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label"
                >
                  Write a short bio about yourself<span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="aboutYourself"
                  rows="3"
                  placeholder="Write a short bio about yourself"
                  name="yourself"
                  value={formData.yourself}
                  onChange={handleChange}
                ></textarea>
              {error.yourself && <p className="ps-2 text-danger">{error.yourself}</p>}
              </div>

               <div class="mb-3">
              <label
                  htmlFor="howKnowConsultancy"
                  style={{ fontSize: ".9rem", fontWeight: "600" }}
                  className="form-label"
                >
                  How did you get to know about Consultancy?<span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="howKnowConsultancy"
                  rows="3"
                  placeholder=" How did you get to know about Consultancy?"
                  name="about"
                  value={formData.about}
                  onChange={handleChange}
                ></textarea>
              {error.about && <p className="ps-2 text-danger">{error.about}</p>}
              </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center m-2">
            <button style={{backgroundColor:"#F2695C",margin:"auto"}} onClick={handleSubmit} disabled={Object.values(error).filter((item)=>item)[0] } className="btn  border-0 fw-medium text-white px-5 py-2">{isLoading?<BeatLoader color="#ffffff" size={15}/>:"Join Now"}</button>
            {Object.values(error).filter((item)=>item)[0] && <p className="text-danger">{Object.values(error).filter((item)=>item)[0]}</p>}
            </div>
          </form>
        </div>
      </section>

          {/* Footer */}
          <Footer />
    </main>
  );
};

export default BecomeMentor;
