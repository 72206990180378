import React from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from "react-accessible-accordion";
import 'react-accessible-accordion/dist/fancy-example.css'; 
import { Col, Row } from "react-bootstrap";


const CareerAccordian = ({heading="",data}) => {
  return (
    <>
      <Row>
        <Col>
          <h2 style={{fontWeight:"600"}} className="mb-4">
          {heading}
          </h2>

          <Accordion allowMultipleExpanded allowZeroExpanded className="m-1 mb-5 ">

            {data?.map((item,idx)=>(
              <>
              <AccordionItem key={idx} className="my-3 border rounded-2">
              <AccordionItemHeading className="fw-semibold fs-5 ">
                <AccordionItemButton style={{ borderRadius: "5px" }}>
                  {item?.question}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="fs-6 fw-medium">
                  {item?.answer}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
              </>
            ))}
            {/* <AccordionItem className="my-3 border rounded-2">
              <AccordionItemHeading className="fw-semibold fs-5 ">
                <AccordionItemButton style={{ borderRadius: "5px" }}>
                  Electric power generation, transmission and distribution
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="fs-6 fw-medium">
                  {" "}
                  Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat
                  occaecat ut occaecat consequat est minim minim esse tempor
                  laborum consequat esse adipisicing eu reprehenderit enim.
                </p>
              </AccordionItemPanel>
            </AccordionItem> */}
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default CareerAccordian;
