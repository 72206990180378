import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
// import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import Card from "../components/common/Card";
import counsellorService from "../services/counsellor";
import domainService from "../services/domain";
import industryService from "../services/industry";
import categoryService from "../services/category";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";

export default function Counsellor() {
  const [counsellorList, setCounsellorList] = useState([]);
 
  const [filterOptions, setFilterOptions] = useState({
    industry: [],
    domain: [],
    category:[]
  });

  const initialFilterQueryState={
    key:"",
    categoryId:"",
    industryId:"",
    domainId:"",
    experience:"",
    price:"",
    page: 1,
    limit:9,
    total: null,
  }

  const [filterQuery,setFilterQuery]=useState(initialFilterQueryState)


  const handleFilter=(e)=>{
     const {name,value}=e.target;
     setFilterQuery((prev)=>{return {...prev,[name]:value}})
  }

   const handleFilterButtons=(id)=>{
      setFilterQuery((prev)=>{return {...prev,categoryId:id}})
    }


    
  const handlePagination = async (page) => {
    // if (page < 1 || page > (filterQuery.total/filterQuery.limit) +1) return;
    // setExtra((prev) => ({ ...prev, page }));
    setFilterQuery(prev=>({...prev,page}))
  };

  const handlePrev = () => {
    if(filterQuery.page>1){
      handlePagination(filterQuery.page - 1);
    }
  };

  const handleNext = () => {
    const {total,page,limit}=filterQuery;
    if(page<total/limit){
      handlePagination(page + 1);
    }
  };
 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const counsellorResponse = await counsellorService.list(filterQuery);
        setCounsellorList(counsellorResponse.data);
        const {total}=counsellorResponse.extra;
        // // if(!filterQuery.page  && !filterQuery.limit && !filterQuery.total){
        // //   // setExtra(counsellorResponse?.extra);
        // // }
         if(filterQuery.total !== total){
          setFilterQuery(prev=>{return {...prev,total}});
         }
       




        if(!filterOptions.domain.length>0){
          const domainResponse = await domainService.list();
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            domain: domainResponse.data,
          }));
        }
    
        if(!filterOptions.industry.length>0){
          const industryResponse = await industryService.list();
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            industry: industryResponse.data,
          }));


        }

        if(!filterOptions.category.length>0){
          const categoryResponse = await categoryService.list();
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            category: categoryResponse.data,
          }));
        }

  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

   
  }, [filterQuery]);

  useEffect(()=>{
  window.scrollTo(0,0);
  },[]);

 
  return (
    <main className="about-page">
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <section className="breadcrumb-wrapper text-center">
        <div className="container">
          <div className="breadcrumb-content">
            <h2>{"Counsellors"}</h2>
          </div>
        </div>
      </section>

      <section className="bg-light p-md-4 p-2">
        <div className="container-lg">

          <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center col-md-11 col-12">
            <div
              style={{ height: "50px" }}
              className="d-flex justify-content-evenly align-items-center bg-white col-sm-12 col-md-6  shadow rounded"
            >
              <input
                style={{ border: "none", margin: "0px",boxShadow:"none" }}
                className="form-control "
                type="text"
                placeholder="Search by mentor name"
                name="key"
                value={filterQuery.key}
                onChange={handleFilter}
              />
              <div style={{cursor:"pointer"}} className="bg-black text-white rounded m-2  d-flex justify-content-center align-items-center p-2 search-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </div>
            </div>
            <span className="m-1 mx-2 fw-semibold text-center">for</span>
            <div className="row col-lg-6 row-cols-2 row-cols-md-2 row-cols-lg-4 g-3 g-md-0 my-1 ps-2">
<div className=" d-flex align-items-center ">

<button onClick={()=>handleFilterButtons("")} style={filterQuery.categoryId ===""?{backgroundImage:"linear-gradient(135deg,#2a5673 14%64%,#1d3557 85%36%)",color:"white"}:{backgroundColor:"white"}} className="rounded-pill btn m-lg-1 fw-medium p-2 col-11">
                All
              </button>

</div>

             
              {filterOptions.category.map((item,index)=>(
                <div className="col p-1">
                      <button key={index}  onClick={()=>handleFilterButtons(item?._id)} style={item._id===filterQuery.categoryId ?{backgroundImage:"linear-gradient(135deg,#2a5673 14%64%,#1d3557 85%36%)",color:"white"}:{backgroundColor:"white"}} className={`rounded-pill btn m-md-1 p-2 fw-medium w-100`}>
                      {item?.title?.split(" ")?.pop()}
                   </button>
                </div>
                 
              ))}
            </div>
          </div>
          <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 gx-md-5 gy-2 mt-4 mx-auto col-md-12">

<div className="col">
<select value={filterQuery.industryId} name="industryId" className="w-100 me-md-4" onChange={handleFilter}>
              <option selected value={""} disabled>Industry</option>
              {filterOptions?.industry?.map((item, index) => {
                return (
                  <option key={index} value={item._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
</div>
           

<div className="col ">
<select name="domainId" value={filterQuery.domainId}  className="w-100" onChange={handleFilter}>
              <option selected value={""} disabled>Domain</option>
              {filterOptions?.domain?.map((item, index) => {
                return (
                  <option key={index} value={item._id}>
                    {item?.name}
                  </option>
                );
              })}
            </select>
</div>
            
            <div className="dropdown col">
              <input type="number" name="experience" min={0} value={filterQuery.experience} onChange={handleFilter} placeholder="Experience" />
            </div>

            <div className="dropdown col">
              <input type="number" name="price" min={0} value={filterQuery.price} placeholder="Price" onChange={handleFilter}/>
            </div>
            <button type="reset" onClick={()=>setFilterQuery(initialFilterQueryState)} className="btn btn-link text-black m-auto clear-btn">
              Clear all filters
            </button>
          </div>
        </div>
      </section>

      <section className="bg-white container m-auto pt-5 ">
        <div className="row row-cols-md-2 row-cols-lg-4 gx-4 gy-5 py-4">
        {counsellorList.map((item, index) => {
          return <div className="col"><Card key={index} data={item} /></div>
        })}
        </div>


        <ul className="pagination d-flex">
            <li>
              <button className="page-link" onClick={handlePrev} disabled={filterQuery.page === 1}>
                <FiChevronsLeft />
              </button>
            </li>
            {Array.from({ length: (filterQuery.total/filterQuery.limit) +1 }, (_, i) => (
              <li key={i}>
                <button
                  className={`page-link ${filterQuery.page === i + 1 ? "current" : ""}`}
                  onClick={() => handlePagination(i + 1)}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li>
              <button className="page-link" onClick={handleNext} disabled={filterQuery.page === filterQuery.total}>
                <FiChevronsRight />
              </button>
            </li>
          </ul>

      </section>



      {/* Footer */}
      <Footer />
    </main>
  );
}
