import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import contactUsService from "../services/contactUs";
import toast from "react-hot-toast";

const BookModal = ({isShow,setIsShow}) => {

  const initialState= {
    name: "",
    phone: "",
    email: "",
    message: "",
  }
  
  const [formData,setFormData]=useState(initialState)
  const [error, setError] = useState({});

  const handleClose=()=>{
    setFormData(initialState);
    setIsShow(false)

  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:value
    })

    setError({
      ...error,
      [name]:""
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newError = {};

    if (!formData.name) {
      newError.name = "Name is required";
    }
    if (!formData.email) {
      newError.email = "Email is required";
    }
    if (!formData.phone) {
      newError.phone = "Mobile Number is required";
    }
    if (!formData.message) {
      newError.message = "Message is required";
    }

    if (Object.keys(newError).length > 0) {
      setError(newError);
      return;
    }


    try {
    
      const res=await contactUsService.save(formData);
      if(res.success){
        toast.success(res.message);
        setFormData(initialState);
        setIsShow(false);
      }

      if(res.error){
        toast.error(res.errors[0].msg)
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error.message)
    }
  };

  return (
    <>
      <Modal show={isShow} onHide={handleClose} centered>
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Title className="m-auto fw-bold">Book Now</Modal.Title>
        <span className="m-auto fw-medium mt-1">After Submit We Will Connect With You Soon</span>
        <Modal.Body>
          <Form className="py-4" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                required
                value={formData.name}
                onChange={handleChange}
                isInvalid={!!error.name}
              />
              <Form.Control.Feedback type="invalid">
                {error.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhone">
              <Form.Control
                type="number"
                name="phone"
                placeholder="Mobile Number"
                required
                value={formData.phone}
                onChange={handleChange}
                isInvalid={!!error.phone}
              />
              <Form.Control.Feedback type="invalid">
                {error.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                required
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!error.email}
              />
              <Form.Control.Feedback type="invalid">
                {error.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                isInvalid={!!error.message}
              />
              <Form.Control.Feedback type="invalid">
                {error.message}
              </Form.Control.Feedback>
            </Form.Group>
            <button className="btn theme-button w-100" type="submit">
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BookModal;
