import React from "react";
import { Col, Row } from "react-bootstrap";

const ProsCons = ({ heading = "", pros = [], cons = [] }) => {
  // const data = {
  //   pros: [
  //     "Opens up a wide number of opportunities in sectors like telecommunication, power, railways, aerospace, IT and artificial intelligence. Hence the scope of it remains wide.",
  //     "Greater flexibility in switching from one industry to another as the application of Electrical and Electronics remains prevalent in multiple industries.",
  //     "Ample number of opportunities in both public and private sector.",
  //   ],
  //   cons: [
  //     "Risk associated with the hazardous profile of the job.",
  //     "Never-ending process of learning/certifications as the engineer needs to be updated on newer process like signal processing and biomedical research.",
  //     "Monetary Packages are expected to grow with substantial experience.",
  //   ],
  // };
  return (
    <>
      <Row className="my-4">
        <Col>
          <h2 style={{ fontWeight: "600" }} className="mb-3">
            {/* Pros & Cons of a Career in Electrical and Electronics Engineering */}
            {heading}
          </h2>
          <div>
            {pros?.length > 0 && (
              <div className="mb-3">
                <h4 className="mb-3"> {"Pros"}</h4>
                <ul className="font-medium career-detail-list">
                  {pros?.map((el, idx) => (
                    <li
                      style={{
                        fontWeight: "500",
                        marginBottom: "5px",
                        fontSize: "18px",
                        color: "#3F3F3F",
                      }}
                      key={idx}
                    >
                      {el?.point}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {cons?.length > 0 && (
              <div className="mb-3">
                <h4 className="mb-3">Cons</h4>
                <ul className="font-medium career-detail-list">
                  {cons.map((el, idx) => (
                    <li
                      style={{
                        fontWeight: "500",
                        marginBottom: "5px",
                        fontSize: "18px",
                        color: "#3F3F3F",
                      }}
                      key={idx}
                    >
                      {el?.point}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProsCons;
