import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import subscription from "../../services/subscription";
import toast from "react-hot-toast";

const Demo = () => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");

  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validate email format
    if (!email || !emailRegex.test(email)) {
      setErr("Please enter a valid email address.");
      return;
    }

    try {
      const res = await subscription.save({ email });
      if (res.success) {
        toast.success(res.message);
        setEmail("");
        setErr(""); // Clear the error message if the submission is successful
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container className="pb-0 p-3" style={{ backgroundColor: "#f3f3f3" }}>
      <Row>
        <Col md={"8"} lg={"7"}>
          <h2 style={{ fontWeight: "600" }}>
            Are you fit for a career in Electrical and Electronics Engineering?
          </h2>
          <h4 className="my-3 my-lg-4">Take the world's most-advanced assessment!</h4>
          <form className="d-flex flex-column flex-md-row align-items-start my-lg-4">
            <div className="col-10">
              <input
                type="email"
                placeholder="Your Email Address"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErr("");
                }}
                required
                className="bg-white rounded-0 m-0 fs-5"
              />
              <p className="text-danger ps-1">{err}</p>
            </div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="theme-button text-nowrap rounded-0 m-3 m-md-0 fs-5 ps-3"
            >
              Take Free Demo
            </button>
          </form>
        </Col>
        <Col md={"4"} className="text-end">
          <img
            src="../images/graduation.png"
            height={"269"}
            width={"269"}
            alt=""
            className="img-fluid object-fit-cover"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Demo;
  