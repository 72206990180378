import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import mainCategoryService from "../services/mainCategory";
import Navbar from "../components/common/Navbar";
import Footer from "../components/other/Footer";

const CategoryDetail = () => {
  const [data, setData] = useState({});
  const params = useParams();

  useEffect(() => {
    mainCategoryService.detail(params?.id).then(res=>{
        if(res.success){
            setData(res.data);
        }
    }).catch(err=>{throw Error(err.message)});
  },[params]);

  useEffect(()=>{
   window.scrollTo(0,0)
  },[])

  return (
  <>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>
    <section className="mt-5 " style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
        <div className="container-md mt-5 p-md-5 mb-2">
          <div className="row text-center mt-5">

        <div style={{textAlign:"center",width:'100%'}} className="sec-heading">
            <h2  className="sec__title"> 
                {/* The Mindler Way */}
                {data?.headingTitle}
                </h2>
                <p className="sec__meta px-md-5 mx-md-5">{
                // "These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that different career paths required different skill sets and our curriculam reflects that"
                 data?.description }</p>
          
            </div>
          </div>
        </div>
        {/* <div style={{ minHeight: "60vh" }} className="p-md-5 col-11  mx-auto">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div
              style={{ borderRadius: "20px 0px 20px 0px" }}
              className="bg-white me-md-3 col-lg-4 h-100 shadow"
            >
              <img src="./images/Group 160.jpg" alt=""  className="img-fluid"/>
            </div>

            <div className="col-lg-5 col-md-6 mx-auto my-2 p-2">
              <h2 className="h4" style={{ color: "#F2695C" }}>
                Advanced Assessments & Best-Fit Matches
              </h2>
              <p className="fw-bold">
                These programs are carefully crafted to meet the specific
                demands of both tech and non-tech industries.We understand that
                different career paths required different skill sets and our
                curriculam reflects that
              </p>
              <Link to={"/contact"}
                className="theme-button my-4"
                style={{ backgroundColor: "#F2695C" }}
              >
                Enquire Now
              </Link>
            </div>
          </div>
        </div> */}

        {
            data?.detailsInfo?.map((item,idx)=>{
                return (
                    <div style={{ minHeight: "60vh" }} className=" p-md-5 ">
                    <div className={`d-flex flex-column justify-content-between align-items-center ${idx%2===0? "flex-md-row":"flex-md-row-reverse"}`}>
                      <div
                        style={{ borderRadius: "20px 0px 20px 0px" }}
                        className="bg-white border me-md-3 col-lg-5 h-100 container shadow"
                      >
                        <img src={item?.image} alt="" className="img-fluid" />
                      </div>
          
                      <div className="col-md-6 mx-auto my-2 p-2">
                        <h2 className="h2" style={{ color: "#F2695C" }}>
                          {/* Advanced Assessments & Best-Fit Matches */}
                          {item?.title}
                        </h2>
                        <p className="fw-medium fs-6">
                          {/* These programs are carefully crafted to meet the specific
                          demands of both tech and non-tech industries.We understand that
                          different career paths required different skill sets and our
                          curriculam reflects that */}
                          {data?.description}
                        </p>
                        {/* <Link to={"/contact"}
                          className="theme-button my-4"
                          style={{ backgroundColor: "#F2695C" }}
                        >
                          Enquire Now
                        </Link> */}
                      </div>
                    </div>
                  </div>
                )
            })
        }
      

        {/* <div style={{ minHeight: "60vh" }} className=" p-md-5">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center ">
            <div
              style={{ borderRadius: "20px 0px 20px 0px" }}
              className="bg-white border me-md-3 col-lg-4  container shadow"
            >
              <img src="./images/Group 160.jpg" alt=""  className="img-fluid"/>
            </div>

            <div className="col-md-6 mx-auto my-2 p-2">
              <h2 className="h4" style={{ color: "#F2695C" }}>
                Advanced Assessments & Best-Fit Matches
              </h2>
              <p className="fw-bold">
                These programs are carefully crafted to meet the specific
                demands of both tech and non-tech industries.We understand that
                different career paths required different skill sets and our
                curriculam reflects that
              </p>
              <Link to={"/contact"}
                className="theme-button my-4"
                style={{ backgroundColor: "#F2695C" }}
              >
                Enquire Now
              </Link>
            </div>
          </div>
        </div> */}
      </section>
      <Footer/>
  </>
  );
};

export default CategoryDetail;
