import React from 'react'
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FiPlus, FiChevronRight } from 'react-icons/fi'
// import { FaRegComment } from 'react-icons/fa'

export default function BlogFeatures({ blog }) {
    // const { title, button, authorurl, commentnum, date, author, images, description } = blog;

    return (
        <div className="blog w-auto p-0">
            <div className="blog-img-box">
                <img className='' style={{height:"246.66px"}} src={blog?.featureImgUrl} alt="Blog" />
                <div className="blog-overlay">
                    <Link to={`/blog-details/${blog?.slug}`} ><FiPlus className="icon" /></Link>
                </div>
            </div>
            <div className="blog-content-box">
                <span className="blog-meta">{blog?.date?.split("T")[0]} By <span>{blog?.author}</span></span>
                <Link to={`/blog-details/${blog?.slug}`}>
                    <h2 style={{height:"56px"}} className="blog-title">
                        {blog?.title}
                    </h2>
                </Link>
                <p className="blog-desc">
                    {blog?.shortDescription}
                </p>
                <div className="blog-footer">
                    <Link className="theme-button" to={`/blog-details/${blog?.slug}`}>Read more <FiChevronRight className="icon" /></Link>
                    {/* <div className="blog-comment">
                        <Link to="/blog-details">Comments <FaRegComment className="comment" /></Link>
                    </div> */}
                </div>
            </div>
        </div>

    )
}

BlogFeatures.propTypes = {
    blog: PropTypes.shape({
        title: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(PropTypes.string).isRequired,
        description: PropTypes.string.isRequired
    })
};
