import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {Tabs, TabPanel, Tab, TabList } from 'react-tabs'

const OurPrograms = ({category}) => {
  return (
    <section className="tabs-wrapper our-learners" >
    <div className="">
      <div className="sec-heading tab-sec-heading text-center">
        <h2 className="text-black sec__title text-center">
          Trained Programmmes for{" "}
          <span className="text-white">Our Learners</span>
        </h2>
        <div className="m-5 col-md-7 mx-auto ">
          <h2 className="sec__title text-white">Our Programs</h2>
          <p className="sec__meta text-white">
            These programs are carefully crafted to meet the specific
            demands of both tech and non-tech industries.WE understand that
            the different career paths required different skill sets and our
            curriculam reflects thats
          </p>
        </div>
      </div>
      <div className="tabs-content-wrap bg-white">
        <Tabs className="service-details-tab tabs-position" selectedTabClassName={"bg-white"}>
          <Col
           lg={9}
           sm={12}
           className="m-auto">

          <TabList  className="our-learners-tabs mx-auto mb-0">
            <Tab className="our-learners-tab p-md-0 ">
              <div className="d-flex flex-column flex-xl-row justify-content-evenly align-items-center mt-2 mt-2xl-0 text-center text-lg-start">
                <img
                  style={{height:"130px",width:"170px",objectFit:"contain"}} 
                  src="./images/home/forStudents.png"
                  alt=""
                />
                <p className="fw-bolder fs-4">{category?.length>0 && category[2]?.title}</p>
              </div>
            </Tab>
            <Tab className="our-learners-tab p-md-0"> 
              <div className="d-flex flex-column flex-xl-row justify-content-evenly align-items-center mt-2 mt-2xl-0 text-center text-lg-start">
                <img
                  style={{height:"130px",width:"170px",objectFit:"contain"}} 
                  src="./images/home/forInstitutions.png"
                  alt=""
                                      />
                <p className="fw-bolder fs-4">{category?.length>0 && category[1]?.title}</p>
              </div>
              </Tab>
              <Tab className="our-learners-tab me-2  me-0"> 
              <div className="d-flex flex-column flex-xl-row justify-content-evenly align-items-center mt-2 mt-2xl-0 text-center text-lg-start">
                <img
                  style={{height:"130px",width:"130px",objectFit:"contain"}} 
                  src="./images/home/forProfessionals.png"
                  alt=""
                  className=""
                />
                <p className="fw-bolder fs-4 text-center">{category?.length>0 && category[0]?.title}</p>
              </div>

              </Tab>
          </TabList>
          <div className="our-learner-content-wrapper p-3 bg-light">
          <TabPanel  className="row row-cols-1 row-cols-md-3 g-2 ">
                 
            {
              category[2]?.items?.map((item,index)=>{
                return (
                  <div key={index} className="p-2">
                  <h6 style={{minHeight:"20px"}} className="fw-bold m-2">{item?.headingTitle?item?.headingTitle:""}</h6>
                   <div>
                    <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>{item?.heading}</h6>
                    <strong className="text-black m-2">{item?.subheading}</strong>
                    <p style={{minHeight:"101px"}} className="m-2 fw-semibold text-secondary">
                      {item?.description}
                    </p>
                    <Link to={"/category-detail/"+item._id} className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                   </div>
                   </div>
                )
              })
            }

            {/* <div>

           
              <div className="m-2">
                <h6 className="fw-bold m-1">CAREER COUNSELLING PROGRAMS</h6>
                <div className="row row-cols-md-3 ">
                 <div>
                  <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>Class 8-9</h6>
                  <strong className="text-black m-2">Stream & Subject Selection</strong>
                  <p className="m-2 fw-semibold text-secondary">
                    These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that the different career paths required different skill sets and our curriculam reflects that
                  </p>
                  <Link className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                 </div>

                 <div>
                  <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>Class 8-9</h6>
                  <strong className="text-black m-2">Stream & Subject Selection</strong>
                  <p className="m-2 fw-semibold text-secondary">
                    These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that the different career paths required different skill sets and our curriculam reflects that
                  </p>
                  <Link className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                 </div>


                 <div>
                  <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>Class 8-9</h6>
                  <strong className="text-black m-2">Stream & Subject Selection</strong>
                  <p className="m-2 fw-semibold text-secondary">
                    These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that the different career paths required different skill sets and our curriculam reflects that
                  </p>
                  <Link className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                 </div>
                </div>
              </div>

              <div className="m-2 my-3">
                <h6 className="fw-bold m-1">CAREER COUNSELLING PROGRAMS</h6>
                <div className="row row-cols-md-3">
                 <div>
                  <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>Class 8-9</h6>
                  <strong className="text-black m-2">Stream & Subject Selection</strong>
                  <p className="m-2 fw-semibold text-secondary">
                    These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that the different career paths required different skill sets and our curriculam reflects that
                  </p>
                  <Link className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                 </div>

                 <div>
                  <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>Class 8-9</h6>
                  <strong className="text-black m-2">Stream & Subject Selection</strong>
                  <p className="m-2 fw-semibold text-secondary">
                    These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that the different career paths required different skill sets and our curriculam reflects that
                  </p>
                  <Link className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                 </div>


                 <div>
                  <h6 className="fw-semibold m-2 h5" style={{color:"#F2695C"}}>Class 8-9</h6>
                  <strong className="text-black m-2">Stream & Subject Selection</strong>
                  <p className="m-2 fw-semibold text-secondary">
                    These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that the different career paths required different skill sets and our curriculam reflects that
                  </p>
                  <Link className="fw-semibold m-2" style={{color:"#F2695C"}}>View Program Details</Link>
                 </div>
                </div>
              </div>
         
            </div> */}
          </TabPanel>
          <TabPanel className="our-learners-tabs-content">
            {
              category[1]?.items?.map((item,index)=>{
                return (
                <div key={index} className="p-2">
                  <h4 style={{color:"#F2695C",minHeight:"60px"}}  className="my-4">{item?.heading}</h4>
                  <p style={{minHeight:"60%"}}>{item?.description}</p>
                  <Link to={"/category-detail/"+item._id} >View Program Details {">"}</Link>
                 </div>
                )
              })
            }

          </TabPanel>
          <TabPanel className="our-learners-tabs-content">
            {
              category[0]?.items?.map((item,index)=>{
                return (
                <div key={index} className="p-2">
                  <h4 style={{color:"#F2695C",minHeight:"60px"}}   className="my-4">{item?.heading}</h4>
                  <p style={{minHeight:"60%"}}>{item?.description}</p>
                  <Link to={"/category-detail/"+item._id} >View Program Details {">"}</Link>
                 </div>
                )
              })
            }
          </TabPanel>
          </div>
          
          </Col>
        </Tabs>
        </div>

      </div>
  </section>
  )
}

export default OurPrograms;