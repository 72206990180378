import React, { useEffect, useState } from "react";
// import PortfolioDetailsContent from "../components/portfolio/PortfolioDetailsContent";
import Navbar from "../components/common/Navbar";
// import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import { useParams } from "react-router-dom";
import careerPathService from "../services/careerPath";
import { Container } from "react-bootstrap";
import Summary from "../components/career/Summary";
import CareerAccordian from "../components/career/CareerAccordian";
import PursueTable from "../components/career/PursueTable";
import Facts from "../components/career/Facts";
import InstitutesTable from "../components/career/InstitutesTable";
import WorkDescription from "../components/career/WorkDescription";
import ProsCons from "../components/career/ProsCons";
import Demo from "../components/career/Demo";

const CareerPathDetail = () => {
  const [data, setData] = useState(null);
  const params = useParams();

  useEffect(()=>{
        if(params?.id){
          careerPathService.details(params.id)
          .then(res=>{
            if(res.success){
              setData(res.data)
            }
          })
          .catch(err=>{})
        }
  },[params.id]);

  useEffect(()=>{
window.scrollTo(0,0)
  },[])


  return (
    <>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      {/* <Breadcrumb title={data?.heading} /> */}

      {/* Portfolio Details */}
      {/* <PortfolioDetailsContent data={data} /> */}

      <Container className="career-detail-wrapper">
        {data && (
          <>
            {/* Summery */}
            <Summary data={data?.sectionOneSummary[0]} />

            {/* Accordian */}
            <CareerAccordian
              heading={data?.sectionTwoHeading}
              data={data?.sectionTwofaq}
            />

            {/* PursueTable */}
            <PursueTable
              heading={data?.sectionThreeHeading}
              data={data?.sectionThreeTable}
            />

            {/* Facts */}
            <Facts
              heading={data?.sectionFourHeading}
              data={data?.sectionFourPoints}
            />

            {/* InstitutesTable */}
            <InstitutesTable
              heading={data?.sectionFiveHeading}
              data={data?.sectionFiveTable}
            />

            {/* Work Description  */}
            <WorkDescription
              heading={data?.sectionSixHeading}
              data={data?.sectionSixPoints}
            />

            {/* Pros & Cons */}
            <ProsCons
              heading={data?.sectionSevenHeading}
              pros={data?.sectionSevenProsPoints}
              cons={data?.sectionSevenConsPoints}
            />
          </>
        )}
        <Demo />
      </Container>

      <Footer />
    </>
  );
};

export default CareerPathDetail;
