import React, { useEffect, useState } from "react";
import PortfolioDetailsContent from "../components/portfolio/PortfolioDetailsContent";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import careerPathService from "../services/careerPath";
// import { Container, Row, Col } from "react-bootstrap";
// import Summary from "../components/career/Summary";
// import CareerAccordian from "../components/career/CareerAccordian";
// import PursueTable from "../components/career/PursueTable"
// import Facts from "../components/career/Facts"
// import InstitutesTable from "../components/career/InstitutesTable"
// import WorkDescription from "../components/career/WorkDescription"
// import ProsCons from "../components/career/ProsCons"
// import Demo from "../components/career/Demo";

const CareerDetail = () => {
  const [data, setData] = useState();
  const params = useParams();

  useEffect(() => {
    if (params?.slug) {
      careerPathService
        .details(params.slug)
        .then((res) => {
          if (res.success) {
            setData(res.data);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, []);
  return (
    <>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title={data?.title} />

      {/* Portfolio Details */}
      <PortfolioDetailsContent data={data} />


      <Footer />
    </>
  );
};

export default CareerDetail;
