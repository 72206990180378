import axios from "./axios";

export default class mainCategoryService{
    static async list(){
        return await axios.get("homepage-menu/list");
    }

    static async detail(id){
        return await axios.get("homepage-menu/details/"+id);
    }
    
}