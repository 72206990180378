import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import Footer from "../components/other/Footer";
// import Card from "../components/common/Card";
// import SimpleTab from "../components/tabs/SimpleTab";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import counsellorService from "../services/counsellor";
import BookModal from "../components/BookModal";

export default function CounsellorDetails() {
  const [counsellor,setCounsellor]=useState({})
  const [isShow,setIsShow]=useState(false);
  const params = useParams();

  useEffect(() => {
    counsellorService
      .details(params.slug)
      .then((res) => {
       setCounsellor(res.data)
      })
      .catch((err) => {
        throw new Error(err);
      });
  },[params.slug]);
  return (
    <main className="about-page">
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title="Best Career Counsellor in Delhi Near Me" />

      {/* <section className="bg-light p-4">
        <div className="d-flex justify-content-evenly align-items-center col-8 m-auto">
          <div
            style={{ height: "40px" }}
            className="d-flex justify-content-evenly align-items-center bg-white w-50 shadow rounded"
          >
            <input
              style={{ border: "none", margin: "0px" }}
              className="form-control"
              type="text"
              placeholder="Search Mentor, company, role, city"
            />
            <div className="bg-black text-white p-1 px-2 rounded m-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </div>
          </div>
          <span>for</span>
          <div>
            <button className="rounded-pill bg-white btn m-1">All</button>
            <button className="rounded-pill bg-white btn m-1">Students</button>
            <button className="rounded-pill bg-white btn m-1">
              Professionals
            </button>
            <button className="rounded-pill bg-white btn m-1">
              Entrepreuners
            </button>
          </div>
        </div>
        <div className="d-flex justify-content-evenly align-items-center col-8 m-auto mt-4">
          <div className="dropdown">
            <button
              className="btn btn-light bg-white dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Sort by Recommended
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="btn btn-light bg-white dropdown-toggle dropdown-toggle-split"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Industry
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="btn btn-light bg-white dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Domain
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="btn btn-light bg-white dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Experience
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          <div className="dropdown">
            <button
              className="btn btn-light bg-white dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Set Price
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </li>
            </ul>
          </div>

          <button className="btn btn-link text-black">
            {" "}
            Clear all filters
          </button>
        </div>
      </section> */}

      <main className="container  row row-md-cols-2 gx-2 m-auto">
        <aside className="col-md-3 col-lg-3 col-sm-12 m-auto  m-md-0 row row-cols-1 gy-5">
          {counsellor?.packages?.map((item,index)=>{
            return (
              <div key={index}
              className="card border-0"
              style={{ width: "18rem", backgroundColor: "#DDEAF2" }}
            >
              <div className="card-body">
                <h6 className="card-title icon-link fw-bolder">
                  <img
                    src="../images/counsellorDetails/Call.svg"
                    alt=""
                    width={25}
                    height={25}
                  />
                  Quick Call
                </h6>
                <h6 className="fw-bold mb-1">
                  {/* ₹ 599/Hr */}
                  ₹ {item?.price}/Hr

                  </h6>
                <strong className="card-subtitle my-1 fw-semibold">
                  {/* 1:1 Video Call On Plateform */}
                  {item?.point1}
                </strong>
                <div style={{ fontSize: "15px" }} className="fw-semibold">
                  <p>
                    {/* Task and Experience */}
                    {item?.point2}
                    </p>
                  <p className="card-text">
                    {/* Achieve your goal faster with the customized roadmap */}
                    {item?.point3}
                  </p>
                </div>
                <button
                  style={{ backgroundColor: "#F2695C" }}
                  className="btn btn-primary w-100 border-0 mt-3"
                  onClick={()=>setIsShow(true)}
                >
                  Book Now
                </button>
              </div>
            </div>
            )
          })}
          {/* <div
            className="card border-0"
            style={{ width: "18rem", backgroundColor: "#DDEAF2" }}
          >
            <div className="card-body">
              <h6 className="card-title icon-link fw-bolder">
                <img
                  src="../images/counsellorDetails/Call.svg"
                  alt=""
                  width={25}
                  height={25}
                />
                Quick Call
              </h6>
              <h6 className="fw-bold mb-1">₹ 599/Hr</h6>
              <strong className="card-subtitle my-1 fw-semibold">
                1:1 Video Call On Plateform
              </strong>
              <div style={{ fontSize: "15px" }} className="fw-semibold">
                <p>Task and Experience</p>
                <p className="card-text">
                  Achieve your goal faster with the customized roadmap
                </p>
              </div>
              <button
                style={{ backgroundColor: "#F2695C" }}
                className="btn btn-primary w-100 border-0 mt-3"
              >
                Book Now
              </button>
            </div>
          </div>

          <div
            className="card border-0"
            style={{ width: "18rem", backgroundColor: "#DDEAF2" }}
          >
            <div className="card-body">
              <h6 className="card-title icon-link fw-bolder">
                <img
                  src="../images/counsellorDetails/Call.svg"
                  alt=""
                  width={25}
                  height={25}
                />
                Quick Call
              </h6>
              <h6 className="fw-bold mb-1">₹ 599/Hr</h6>
              <strong className="card-subtitle my-1 fw-semibold">
                1:1 Video Call On Plateform
              </strong>
              <div style={{ fontSize: "15px" }} className="fw-semibold">
                <p>Task and Experience</p>
                <p className="card-text">
                  Achieve your goal faster with the customized roadmap
                </p>
              </div>
              <button
                style={{ backgroundColor: "#F2695C" }}
                className="btn btn-primary w-100 border-0 mt-3"
              >
                Book Now
              </button>
            </div>
          </div>

          <div
            className="card border-0"
            style={{ width: "18rem", backgroundColor: "#DDEAF2" }}
          >
            <div className="card-body">
              <h6 className="card-title icon-link fw-bolder">
                <img
                  src="../images/counsellorDetails/Call.svg"
                  alt=""
                  width={25}
                  height={25}
                />
                Quick Call
              </h6>
              <h6 className="fw-bold mb-1">₹ 599/Hr</h6>
              <strong className="card-subtitle my-1 fw-semibold">
                1:1 Video Call On Plateform
              </strong>
              <div style={{ fontSize: "15px" }} className="fw-semibold">
                <p>Task and Experience</p>
                <p className="card-text">
                  Achieve your goal faster with the customized roadmap
                </p>
              </div>
              <button
                style={{ backgroundColor: "#F2695C" }}
                className="btn btn-primary w-100 border-0 mt-3"
              >
                Book Now
              </button>
            </div>
          </div> */}

        </aside>


        <section
          style={{ backgroundColor: "transparent" }}
          className="col-md-9 p-md-5 tabs-wrapper"
        >
          <div className="mb-3 border-0">
            <div className="row gx-4">
              <div className="col-md-4 rounded">
                <img
                src={counsellor?.featureImage}
                  // src="https://th.bing.com/th/id/R.da2e546841da40cdcf60061743233500?rik=IeO7Sr%2fkUW54wQ&riu=http%3a%2f%2fwww.venmond.com%2fdemo%2fvendroid%2fimg%2favatar%2fbig.jpg&ehk=JihI5nQ0BOd0W%2bZVhtIWmqwac0NMyRMOV7%2bzryywg%2fg%3d&risl=&pid=ImgRaw&r=0"
                  className="img-fluid rounded-4 counsellor-img"
                  alt="..."
               
                />
              </div>
              <div className="col-md-8 p-3 p-md-0">
                <div className="card-body">
                  <small className="text-dark-emphasis fw-semibold">
                    {/* ID:113331 */}
                    ID:{counsellor?.empId}
                  </small>
                  <div>
                    <h5 className="card-title fw-semibold">
                      {/* Manishankar Panda */}
                      {counsellor?.name}
                    </h5>
                    <strong className="fw-semibold my-1 text-black">
                      {/* Senior Analyst */}
                      {counsellor?.domainDetails?.name}
                    </strong>
                  </div>
                  <div
                    style={{ fontSize: "15px" }}
                    className="bg-light d-flex justify-content-around  align-items-center fw-bold w-md-75 my-2 py-1"
                  >
                    <span className="icon-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-currency-rupee"
                        viewBox="0 0 16 16"
                      >
                        <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
                      </svg>
                      {/* 650/Hr */}
                      {counsellor?.price}/Hr
                    </span>
                    <a  href={`tel:${counsellor?.quickCall}`}  style={{ color: "#F2695C" }} className="icon-link quick-call">
                      <img src="../images/counsellorDetails/Quick Call.svg" alt="" />
                    Quick Call
                    </a>
                    <span style={{ color: "#2C466C" }} className="icon-link">
                      <img
                        className="img-fluid object-fit-contain"
                        src="../images/counsellorDetails/Packages.svg"
                        alt=""
                      />
                      Packages
                    </span>
                  </div>
                  <div
                    style={{ fontSize: "15px" }}
                    className="row row-cols-1 g-1  px-3  mt-1 fw-bold"
                  >
                    <span className="icon-link">
                      <img src="../images/counsellorDetails/consulting.svg" alt=""/>
                      {/* Consulting */}
                      {counsellor?.profile}
                    </span>
                    <span className="icon-link">
                      <img src="../images/counsellorDetails/student.svg" alt="" />
                      {/* Student */}
                      {counsellor?.categoryDetails?.title}
                    </span>
                    <span className="icon-link">
                      <img src="../images/counsellorDetails/manufacturing.svg" alt=""/>
                      {/* Manufacturing */}
                      {counsellor?.industryDetails?.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tabs-content-wrap">
            <Tabs selectedTabClassName="mentor-details-tab--selected">
              <TabList className="mentor-details-tab ">
                {/* {tabsnav.map((item, index) => {
                                return <Tab key={index}>{item.title}</Tab>
                            })} */}
                <Tab>About Mentor</Tab>
                <Tab>Experience</Tab>
              </TabList>

              <TabPanel className="pt-3">
                {/* Analytics professional with a demonstrated history of working in
                the management consulting and mining industry. Having over 5
                years of work experience in the Mining & Metals industry with a
                spike in following areas of: 1. Operational performance
                management 2. Cost optimization 3. Digital transformation 4. Due
                diligence 5. Techno-Economic studies 6. Strategic marketing 7.
                Product management 8. Team management With excellent domain
                knowledge, brings in skills of Financial Modelling, Market
                Research, Data Analytics Simulation and Advance analytics.
                Published Papers, attended workshops & conferences and an active
                participant in extra co-curricular activities. */}
                <div dangerouslySetInnerHTML={{__html:counsellor?.about}}></div>
              </TabPanel>

              <TabPanel>
                <div className="pt-3">
          
                    {
                      counsellor?.experience?.map((item,index)=>{
                        return (
                          <>
                  <div className="d-flex align-items-start my-4 w-75">
                       <div
                      style={{ backgroundColor: "#2C466C" }}
                      className="rounded mx-2 p-3"
                    >
                      <img src="../images/Group 194.png" alt=""/>
                    </div>
                          <div className="d-flex flex-column justify-content-between">
                          <h6 style={{ fontSize: "14px" }} className="fw-bold">
                            {/* Associate Consultant */}
                            {item?.profile}
                          </h6>
                          <strong
                            style={{ fontSize: "" }}
                            className="text-black fw-medium"
                          >
                            {/* EY */}
                            {item?.company}
                          </strong>
                          <p style={{ fontSize: "14px", fontWeight: "500" }}>
                            {/* Jul 2021-Aug 2021 1Months */}
                           From {item?.startDate?.split("T")[0]} To {item.endDate ? item?.endDate.split("T")[0] :"Present"}
                          </p>
                        </div>
                  </div>

                        </>
                        )
                      })
                    }

                    {/* <div className="d-flex flex-column justify-content-between">
                      <h6 style={{ fontSize: "14px" }} className="fw-bold">
                        Associate Consultant
                      </h6>
                      <strong
                        style={{ fontSize: "" }}
                        className="text-black fw-medium"
                      >
                        EY
                      </strong>
                      <p style={{ fontSize: "14px", fontWeight: "500" }}>
                        Jul 2021-Aug 2021 1Months
                      </p>
                    </div> */}

                  {/* <div className="d-flex align-items-start my-4 w-75">
                    <div
                      style={{ backgroundColor: "#2C466C" }}
                      className="rounded mx-2 p-3"
                    >
                      <img
                        width={35}
                        height={35}
                        className="object-fit-contain"
                        src="../images/Group 195.png"
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <h6 style={{ fontSize: "14px" }} className="fw-bold">
                        Senior Placement Coordinator{" "}
                      </h6>
                      <strong
                        style={{ fontSize: "" }}
                        className="text-black fw-medium"
                      >
                        Svkm's Narsee Monjee Institute Of Management Studies
                        (NMIMS)
                      </strong>
                      <p style={{ fontSize: "14px", fontWeight: "500" }}>
                        Nov 2018 May 2020. 1 Year & 5 Months
                      </p>
                      <p style={{ fontSize: "14px", fontWeight: "600" }}>
                        Responsible For Corporate Tie-Ups For Placements And
                        Internships Across Different Programs
                      </p>
                    </div>
                  </div> */}
                </div>
              </TabPanel>
              {/* {tabslist.map((tab, index) => {
                            return (
                                <TabPanel className="tabs-content" key={index}>
                                    <div className={'tabs-content-flex ' + tab.img_position}>
                                        {tab.img?
                                            <div className="tabs-left">
                                                <img src={tab.img} alt="Tabs" />
                                            </div> : ''
                                        }
                                        <div className="tabs-right">
                                            {tab.desc?
                                                <p className="tabs-desc">
                                                    {tab.desc}
                                                </p> : ''
                                            }
                                            {tab.button?
                                                <Link to={tab.buttonurl} className="theme-button">{tab.button} <FiChevronRight className="icon" /></Link>
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </TabPanel>
                            )
                        })} */}
            </Tabs>
          </div>
        </section>
      </main>

      {/* <section className="bg-white container m-auto pt-5 row row-cols-4 gx-5">
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
      </section> */}

      {/* Footer */}
      <Footer />
      <BookModal isShow={isShow} setIsShow={setIsShow}/>
    </main>
  );
}
