import { FiChevronRight } from "react-icons/fi";
import React, { useState } from "react";
import contactUsService from "../../services/contactUs";
import toast from "react-hot-toast";

export default function FooterForm() {
  const initialState = {
    name: "",
    email: "",
    phone:"",
    message: "",
  };

  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setError({
      ...error,
      [name]: "",
    });
  };

  const validation = () => {
    const error = {};
    let isValidated = true;

    if (!formData.name) {
      error.name = "Name is required";
      isValidated = false;
    }

    
    if (!formData.phone) {
        error.phone = "Phone Number is required";
        isValidated = false;
      }

    if (!formData.email) {
      error.email = "Email is required";
      isValidated = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      error.email = "Email is invalid";
      isValidated = false;
    }

    if (!formData.message) {
      error.message = "Message is required";
      isValidated = false;
    }

    setError(error);
    return isValidated;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validation()) {
      try {
        const res = await contactUsService.save(formData);
        if (res.success) {
          toast.success(res.message);
          setFormData(initialState); 
        } else {
          toast.error(res.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div className="footer-widget footer-get-in-touch">
      <h2>Get In Touch.</h2>
      <form className="get-in-touch" onSubmit={handleSubmit}>
        <div>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={formData.name}
          onChange={handleChange}
        />
        {error.name && <span className="text-danger">{error.name}</span>}
        </div>
       

        <input
          type="text"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
        />
        {error.phone && <span className="text-danger">{error.phone}</span>}

        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
        />
        {error.email && <span className="text-danger">{error.email}</span>}

        <textarea
          placeholder="Write Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        {error.message && <span className="text-danger">{error.message}</span>}

        <button type="submit" className="theme-button">
          Send Us Message <FiChevronRight className="icon" />
        </button>
      </form>
    </div>
  );
}
