import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Card = ({data}) => {
const history=useHistory();

  return (
    <div style={{cursor:"pointer"}} onClick={()=> history.push(`/counsellor-details/${data?.slug}`)} className="card border-0 shadow p-1">
    <img
       src={data?.thumbnailImage}
      // src="https://th.bing.com/th/id/R.da2e546841da40cdcf60061743233500?rik=IeO7Sr%2fkUW54wQ&riu=http%3a%2f%2fwww.venmond.com%2fdemo%2fvendroid%2fimg%2favatar%2fbig.jpg&ehk=JihI5nQ0BOd0W%2bZVhtIWmqwac0NMyRMOV7%2bzryywg%2fg%3d&risl=&pid=ImgRaw&r=0"
      className="card-img-top counsellor-card-img"
      alt="..."
    />
    <div className="card-body">
      <div>
        <h5 className="">{data?.name}</h5>
        <h6 className='my-2'>{data?.domainDetails?.name}</h6>
      </div>
      <div style={{fontSize:'12px'}} className="bg-light d-flex justify-content-evenly align-items-center fw-semibold w-100 mt-1 ">
        <span className="icon-link">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-currency-rupee"
            viewBox="0 0 16 16"
          >
            <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z" />
          </svg>
          {/* 650/Hr */}
          {data?.price}/Hr
        </span>
        <span style={{color:"#F2695C"}} className="icon-link mx-1">
       <img width={13} className='img-fluid' src='./images/counsellorDetails/Quick Call.svg' alt=''/>
          <a href={`tel:${data?.quickCall}`} style={{color:"#F2695C",textWrap:"nowrap"}}>Quick Call</a></span>
        <span style={{color:"#2C466C"}} className="icon-link mx-1">
        <img className='img-fluid' width={13}  src='./images/counsellorDetails/Packages.svg' alt=''/>
          Packages</span>
      </div>
       <div style={{fontSize:"13px"}} className="row row-cols-1  mt-1 fw-semibold">
          <span className="icon-link">
           <img width={13} className='img-fluid' alt='' src='./images/counsellorDetails/consulting.svg'/>
             {data?.profile}
             </span>
          <span className="icon-link">
       <img width={13} className='img-fluid' alt='' src='./images/counsellorDetails/student.svg'/>
       {data?.categoryDetails?.title}</span>
          <span className="icon-link">
       <img width={13} className='img-fluid' alt='' src='./images/counsellorDetails/manufacturing.svg'/>
        
          {data?.industryDetails?.name}</span>

       </div>
    </div>
  </div>
  )
}

export default Card