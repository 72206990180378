import React from 'react'
import { Col, Row } from 'react-bootstrap'
import "./style.css"
const Facts = ({heading="",data=[]}) => {
  // const data=[
  //   "Most colleges have eligibility criteria of minimum 50% aggregate marks in Class XII / and some of minimum 60%",
  //   "Must have passed class 12thor senior secondary examination with at least 50% marks including English",
  //   "Some exams require JEE score as minimum eligibility",
  //   "Some colleges/ universities also have an age limit",
  //   "Electronics Engineering as a separate programme is available in some colleges",
  // ]
  return (
    <>
    <Row className='my-4'>
      <Col>
          <h2 style={{fontWeight:"600"}} className='mb-4 font-black'>
            {/* Important Facts */}
            {heading}
            </h2>
          <ul className='career-detail-list '>
            {
              data.map((el,idx)=>(
                <li style={{fontWeight:"600",marginBottom:"5px",fontSize:"18px",color:"#3F3F3F"}}   key={idx}>{el?.point}</li>
              ))
            }

          </ul>
         
      </Col>
    </Row>
    </>
  )
}

export default Facts