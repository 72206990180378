import React, { useState, useEffect } from "react";
import Navbar from "../components/common/Navbar";
import Breadcrumb from "../components/common/Breadcrumb";
import BlogsContainer from "../components/blog/BlogsContainer";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import CtaForm from "../components/other/CtaForm";
import Footer from "../components/other/Footer";
import blogService from "../services/blog";

export default function BlogGridPage() {
  const [blogList, setBlogList] = useState([]);
  const [extra, setExtra] = useState({
    page: 1,
    limit: 3,
    total: 1,
  });

  const handlePagination = async (page) => {
    if (page < 1 || page > (extra.total/3) +1) return;
    setExtra((prev) => ({ ...prev, page }));
  };

  const handlePrev = () => {
    handlePagination(extra.page - 1);
  };

  const handleNext = () => {
    handlePagination(extra.page + 1);
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await blogService.list(extra);
        if (response.success) {
          setBlogList(response.data);
          setExtra(response.extra);
        }
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, [extra.page]);

  return (
    <>
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title="Blog List" />

      {/* Blog Grid */}
      <section className="blog-wrapper blog-grid-page">
        <div className="container">
          <div className="blog-grid">
            <BlogsContainer blogList={blogList} />
          </div>
          <ul className="pagination d-flex">
            <li>
              <button className="page-link" onClick={handlePrev} disabled={extra.page === 1}>
                <FiChevronsLeft />
              </button>
            </li>
            {Array.from({ length: (extra.total/3) +1 }, (_, i) => (
              <li key={i}>
                <button
                  className={`page-link ${extra.page === i + 1 ? "current" : ""}`}
                  onClick={() => handlePagination(i + 1)}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li>
              <button className="page-link" onClick={handleNext} disabled={extra.page === extra.total}>
                <FiChevronsRight />
              </button>
            </li>
          </ul>
        </div>
      </section>

      {/* CTA Form */}
      <div className="form-white-bg">
        <CtaForm />
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
