import React from "react";
import { Col, Row } from "react-bootstrap";
const Summary = ({data}) => {

  // const data ={
  //   title:"Electrical and Electronics Engineering",
  //   description:`design, maintain and develop electrical control systems and
  //           equipment. They work keeping in view economy and precision of their
  //           methodology to build reliable systems that find place across various
  //           domains including transportation, telecommunications, power
  //           generation, signal transmission and distribution and scientific
  //           research. Electrical Engineers concern themselves with developing
  //           circuits and generating, distributing and using electrical energy.
  //           Whereas, Electronics engineers are concerned about the behavior of
  //           these circuits and manipulating them to work automatically. The
  //           applications of electrical/ electronic engineering are quite diverse
  //           ranging from houses to commercial offices to industries. With the
  //           boom in the electronics sector in India, aspirants looking for a
  //           specialization in this branch of engineering are likely to have
  //           extremely good future prospects`,
  //           image:"../images/R.jpg"

  // }
  return (
    <>
      <Row className="m-auto mt-5 pt-5 pb-5 align-items-center">
        <Col
          
          lg={8}
          style={{ minHeight: "58vh" }}
          className="d-flex flex-column justify-content-between"
        >
          <div>
          <h1
            style={{ color: "#233D63", fontWeight: "700" }}
            className="fs-1"
          >
            {data?.title}
          </h1>
          <h2 style={{ fontSize: "32px",fontWeight:"600" }} className="my-4"> {data && "Summary"}</h2>
          </div>
        
          <p style={{ fontSize: "18px" }} className=" fw-medium pe-lg-5 pb-3 pb-lg-0">
            {/* <strong className="text-black">
              Electrical and Electronics Engineers
            </strong>{" "} */}
                {data?.description}
          </p>
        </Col>
        <Col  lg={"4"} className="text-center" >
        { data &&  <img
            style={{ height: "429px", width: "378px" }}
            src={data?.image}
            alt=""
            className="img-fluid object-cover"
          />
        }
        </Col>
      </Row>
    </>
  );
};

export default Summary;
