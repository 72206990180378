import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'

const InstitutesTable = ({heading="",data=[]}) => {

  const style={
    th:"p-4 fw-semibold align-middle text-start",
    td:"fs-5 fw-medium  align-middle ps-4  pe-5 py-4"
  }

  // const data=[
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},
  //   {college:"Indian Institute of Technology",location:"Multiple locations",website:"www.iitd.ac.in/ /"},

  // ]
  return (
    <>
{
  data?.length>0 && 
  <Row className='my-4'>
  <Col>
      <h2 style={{fontWeight:"600"}} className='mb-3'>
        {/* Leading Institutes */}
        {heading}
        </h2>
      {/* <h3 className='mb-4'>
        Top Electrical and Electronics Engineering Institutes in India
        </h3> */}
 
 <div className='overflow-x-auto'>
 <Table style={{tableLayout:"auto"}} bordered striped hover>
        <thead >
          <tr  className='fs-4'>
            <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>College</th>
            <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>Location</th>
            <th style={{backgroundColor:"#F3F3F3"}} className={style.th}>Website</th>
          </tr>
        </thead>
        <tbody>
          {
            data?.map((el,idx)=>(
              <tr key={idx}>
              <td  style={{color:"#707070"}} className={style.td}>{el?.collage}</td>
              <td  style={{color:"#707070"}} className={style.td}>{el?.location}</td>
              <td className={style.td}><a className={"text-info"} href={el?.website}>{el?.website}</a></td>
            </tr>
            ))
          }
          {/* <tr>
            <td><span>Path 1</span></td>
            <td>Clear Class XII with Science Stream with Maths</td>
            <td>Pursue B.Tech in Electrical Engineering / Electrical and Electronics Engineering / Electronics and Communication Engineering for 4 years</td>
            <td>Pursue M.Tech for 2-3 years</td>
            <td>Pursue Ph.D for 2-3 years</td>
          </tr> */}
        </tbody>
      </Table>
 </div>
  
     
  </Col>
</Row>
}
 
    </>
  )
}

export default InstitutesTable