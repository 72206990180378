import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  FiChevronDown,
  // FiShoppingCart,
  // FiSearch,
  FiChevronRight,
} from "react-icons/fi";
import HeaderBar from "../common/HeaderBar";
// import { ProductConsumer } from "../../productcontext";
import $ from "jquery";
// import MenuCart from "./menucart/MenuCart";
// import { FaTimes } from "react-icons/all";
import sectiondata from "../../store/store";
import mainCategoryService from "../../services/mainCategory";

export default function Navbar() {
  // const [searchOpen, setsearchOpen] = useState(false);
  const [navOpen, setnavOpen] = useState(false);
  const [categoryMenu, setCategoryMenu] = useState([]);

  /*====  side-widget-menu  =====*/
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item",
    function () {
      $(".side-menu-wrap .side-menu-ul .sidenav__item").removeClass("active");
      $(this).toggleClass("active");
    }
  );
  $(document).on(
    "click",
    ".side-menu-wrap .side-menu-ul .sidenav__item.active",
    function () {
      $(".side-menu-wrap .side-menu-ul .sidenav__item.active").removeClass(
        "active"
      );
    }
  );

  $(window).on("scroll", function () {
    // Header Menu Fixed
    if ($(window).scrollTop() > 30) {
      $("body").addClass("header-menu-fixed");
      $(".scroll-d-block").addClass("d-block");
    } else {
      $("body").removeClass("header-menu-fixed");
      $(".scroll-d-block").removeClass("d-block");
    }
  });

  useEffect(() => {
    mainCategoryService
      .list()
      .then((res) => {
        if (res.success) {
          setCategoryMenu(res.data);
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <>
      <HeaderBar />

      <div className="header-menu-wrapper scroll-d-block">
        <div className="header-menu-container container">
          <div className="header-menu-flex">
            <div className="header-menu-left">
              <Link to="/">
                <img
                  src={"../headerLogo.svg"}
                  // {sectiondata.HeaderData.HeaderMenuBar.logourl}
                  alt="Logo"
                 
                  width="280px"
                  className="mb-md-2"


                />
              </Link>
            </div>
            <div className="header-menu-right">
              <nav>
                <ul>
                  <li>
                    <Link to="">
                      {categoryMenu[2]?.title}{" "}
                      {categoryMenu[2]?.items?.length > 0 ? (
                        <FiChevronDown className="menu-icon" />
                      ) : (
                        ""
                      )}
                    </Link>
                    {categoryMenu[2]?.items?.length > 0 ? (
                      <ul style={{width:"90vw",left:"-35vw"}} className="dropdown-menu-item row row-cols-3">
                        {categoryMenu[2]?.items?.map((item, index) => {
                          return (
                            <li key={index}>
                              <div className="p-2">
                                <h6 style={{minHeight:"25px"}} className="fw-bold m-2">
                                  {item?.headingTitle}
                                </h6>
                                {/* <div className="row row-cols-md-3 "> */}
                                <div>
                                  <h6
                                    className="fw-semibold m-2 h5"
                                    style={{ color: "#F2695C" }}
                                  >
                                    {item?.heading}
                                  </h6>
                                  <strong className="text-black m-2">
                                    {item?.subheading}
                                  </strong>
                                  <p style={{minHeight:"80px"}} className="m-2 fw-semibold text-secondary">
                                    {item?.description}
                                  </p>
                                  <Link
                                    className="fw-semibold m-2"
                                    style={{ color: "#F2695C" }}
                                    to={"/category-detail/"+item?._id}
                                  >
                                    View Program Details
                                  </Link>
                                </div>
                                {/* </div> */}
                              </div>
                            </li>
                          );
                        })}
                   </ul>
                    ) : (
                      ""
                    )}
                  </li>

                  <li>
                    <Link to="">
                      {categoryMenu[1]?.title}{" "}
                      {categoryMenu[1]?.items?.length > 0 ? (
                        <FiChevronDown className="menu-icon" />
                      ) : (
                        ""
                      )}
                    </Link>
                    {categoryMenu[1]?.items?.length > 0 ? (
                      <ul style={{left:"-45vw"}} className="dropdown-menu-item d-flex">
                        {categoryMenu[1]?.items?.map((item, index) => {
                          return (
                            <li style={{width:"30vw"}}  key={index}>
                              <div className="p-2">
                                <h6 className="fw-bold m-2">
                                  {item?.headingTitle}
                                </h6>
                                {/* <div className="row row-cols-md-3 "> */}
                                <div>
                                  <h6
                                    className="fw-semibold m-2 h5"
                                    style={{ color: "#F2695C" }}
                                  >
                                    {item?.heading}
                                  </h6>
                                  <strong className="text-black m-2">
                                    {item?.subheading}
                                  </strong>
                                  <p style={{minHeight:"80px"}} className="m-2 fw-semibold text-secondary">
                                    {item?.description}
                                  </p>
                                  <Link
                                    className="fw-semibold m-2"
                                    style={{ color: "#F2695C" }}
                                    to={"/category-detail/"+item?._id}
                                  >
                                    View Program Details
                                  </Link>
                                </div>
                                {/* </div> */}
                              </div>
                            </li>
                          );
                        })}



                   </ul>
                    ) : (
                      ""
                    )}
                  </li>

                  <li>
                    <Link to="">
                      {categoryMenu[0]?.title}{" "}
                      {categoryMenu[0]?.items?.length > 0 ? (
                        <FiChevronDown className="menu-icon" />
                      ) : (
                        ""
                      )}
                    </Link>
                    {categoryMenu[0]?.items?.length > 0 ? (
                      <ul style={{width:"90vw",left:"-800px"}} className="dropdown-menu-item row row-cols-3">
                        {categoryMenu[0]?.items?.map((item, index) => {
                          return (
                            <li key={index}>
                              <div className="p-2">
                                <h6 className="fw-bold m-2">
                                  {item?.headingTitle}
                                </h6>
                                {/* <div className="row row-cols-md-3 "> */}
                                <div>
                                  <h6
                                    className="fw-semibold m-2 h5"
                                    style={{ color: "#F2695C" }}
                                  >
                                    {item?.heading}
                                  </h6>
                                  <strong className="text-black m-2">
                                    {item?.subheading}
                                  </strong>
                                  <p style={{minHeight:"150px"}} className="m-2 fw-semibold text-secondary">
                                    {item?.description}
                                  </p>
                                  <Link
                                    className="fw-semibold m-2"
                                    style={{ color: "#F2695C" }}
                                    to={"/category-detail/"+item?._id}
                                  >
                                    View Program Details
                                  </Link>
                                </div>
                                {/* </div> */}
                              </div>
                            </li>
                          );
                        })}
                   </ul>
                    ) : (
                      ""
                    )}
                  </li>

                  {/* {
                                        categoryMenu.map((item,index)=>{
                                            return (
                                                <li key={index}>
                                                <Link to={item?.path ?item.path:"/"}>
                                                    {item?.title} { item?.items?.length>0 ? <FiChevronDown className="menu-icon" /> : '' }
                                                </Link>
                                                {
                                                    item?.items?.length>0 ?
                                                        (
                                                            <ul className="dropdown-menu-item">
                                                                {
                                                                    item.items.map((dp_item, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <Link to={dp_item.path?"":''}>{dp_item.headingTitle?dp_item.headingTitle:dp_item.heading} {dp_item.headingTitle ? <FiChevronRight className="icon" /> : ''}</Link>
                                                                              
                                                                                {
                                                                                    dp_item.headingTitle ?
                                                                                        (
                                                                                            <ul className="dropdown-menu-item">
                                                                                               
                                                                                                        <li>
                                                                                                            <Link to={""}>{dp_item.heading}</Link>
                                                                                                        </li>
                                                                                            </ul>
                                                                                        ) : ''
                                                                                }
                                                                            </li>
                                                                        )
                                                                    })
                                                                }

                                                            </ul>
                                                        ) : ''
                                                }
                                            </li>
                                            )
                                        })
                                    } */}

                  {sectiondata.HeaderData.HeaderMenuBar.menus.map(
                    (item, index) => {
                      return (
                        <li key={index}>
                          <Link to={item.path}>
                            {item.title}{" "}
                            {item.dropdown ? (
                              <FiChevronDown className="menu-icon" />
                            ) : (
                              ""
                            )}
                          </Link>
                          {item.dropdown ? (
                            <ul style={{left:"-8vw",overflow:"clip",padding:"0px"}} className="dropdown-menu-item">
                              {item.dropdown.map((dp_item, i) => {
                                return (
                                  <li className="border-bottom p-1" key={i}>
                                    <Link to={dp_item.path}>
                                      {dp_item.title}{" "}
                                      {dp_item.dep_dropdown ? (
                                        <FiChevronRight className="icon" />
                                      ) : (
                                        ""
                                      )}
                                    </Link>
                                    {dp_item.dep_dropdown ? (
                                      <ul className="dropdown-menu-item">
                                        {dp_item.dep_dropdown.map(
                                          (dp_item_inr, i2) => {
                                            return (
                                              <li key={i2}>
                                                <Link to={dp_item_inr.path}>
                                                  {dp_item_inr.title}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    ) : (
                                      ""
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          ) : (
                            ""
                          )}
                        </li>
                      );
                    }
                  )}
                </ul>
              </nav>
              <div className="header-menu-right-flex">
                {/* <div className="header-menu-cart">
                                    <ul>
                                        <li>
                                            <div className="cart__icon">
                                                <Link to="#">
                                                    <FiShoppingCart />
                                                </Link>
                                                <span className="cart__item-count">
                                                    <ProductConsumer>
                                                        {(value) => {
                                                            const { cart } = value;
                                                            if (cart.length > 0) {
                                                                return (
                                                                    <span>
                                                                        {cart.length}
                                                                    </span>
                                                                )
                                                            } else {
                                                                return (
                                                                    "0"
                                                                );
                                                            }
                                                        }}
                                                    </ProductConsumer>
                                                </span>
                                            </div>

                                            <MenuCart />
                                        </li>
                                        <li>
                                            <Link to="#" className="search-button" onClick={() => setsearchOpen(!searchOpen)}>
                                                {
                                                    searchOpen ?
                                                        <FaTimes className="search-icon" />
                                                    : <FiSearch className="search-icon" />
                                                }
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className={searchOpen ? 'header-menu-searchform active' : 'header-menu-searchform'}>
                                    <form action="#">
                                        <input className="form-control" type="text" placeholder="Search by keywords..." />
                                        <div className="form-icon">
                                            <FiSearch className="search-icon" />
                                        </div>
                                    </form>
                                </div> */}
                <div
                  className="side-menu-open"
                  onClick={() => setnavOpen(!navOpen)}
                >
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                  <span className="menu__bar"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={navOpen ? "side-nav-container active" : "side-nav-container"}
      >
        <div className="humburger-menu">
          <div
            className="humburger-menu-lines side-menu-close"
            onClick={() => setnavOpen(!navOpen)}
          ></div>
        </div>
        <div className="side-menu-wrap">
          <ul className="side-menu-ul">
            {categoryMenu.map((item, index) => {
              return (
                <li className="sidenav__item" key={index}>
                  {/* <a href={item.path ? "" : ""}>{item.title}</a> */}
                  <Link to={item.path ? "" : ""}>{item.title}</Link>

                  {item?.items?.length > 0 ? (
                    <span className="menu-plus-icon"></span>
                  ) : (
                    ""
                  )}
                  {item?.items?.length > 0 ? (
                    <ul className="side-sub-menu">
                      {item?.items?.map((dp_item, i) => {
                        return (
                          <li key={i}>
                            {/* <a href={""}>
                              {
                              // dp_item.headingTitle
                              // ? dp_item.headingTitle: 
                                dp_item.heading
                                }
                            </a> */}
                            <Link to={"/category-detail/"+dp_item?._id}>
                              {
                              // dp_item.headingTitle
                              // ? dp_item.headingTitle: 
                                dp_item.heading
                                }
                            </Link>
                            {
                              // dp_item.headingTitle ? (
                              //   <ul className="dropdown-menu-item">
                              //     <li>
                              //       <Link to={""}>{dp_item.heading}</Link>
                              //     </li>
                              //   </ul>
                              // ) : (
                              //   ""
                              // )

                              // (
                              //     <ul className="dropdown-menu-item">
                              //         {dp_item.dep_dropdown.map((dp_item_inr, i2) => {
                              //             return (
                              //                 <li key={i2}>
                              //                     <Link to={dp_item_inr.path}>{dp_item_inr.title}</Link>
                              //                 </li>
                              //             )
                              //         })}
                              //     </ul>
                              // ) : ''
                            }
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
            {sectiondata.HeaderData.HeaderMenuBar.menus.map((item, index) => {
              return (
                <li className="sidenav__item" key={index}>
                  {/* <a href={item.path}>{item.title}</a> */}
                  <Link to={item.path}>{item.title}</Link>

                  {item.dropdown ? (
                    <span className="menu-plus-icon"></span>
                  ) : (
                    ""
                  )}
                  {item.dropdown ? (
                    <ul className="side-sub-menu">
                      {item.dropdown.map((dp_item, i) => {
                        return (
                          <li key={i}>
                          {!dp_item.dep_dropdown &&
                          //  <a href={dp_item.path}>{dp_item.title}</a>
                          <Link to={dp_item.path}>{dp_item.title}</Link>

                           }  
                            {dp_item.dep_dropdown ? (
                              <ul className="dropdown-menu-item">
                                {dp_item.dep_dropdown.map((dp_item_inr, i2) => {
                                  return (
                                    <li key={i2}>
                                      <Link to={dp_item_inr.path}>
                                        {dp_item_inr.title}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              ""
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </li>
              );
            })}
            {/* <li className="sidenav__item sidenav__item2 text-center">
              <Link to="/sign-up">sign up</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
