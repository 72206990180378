import React from 'react'

export default function SectionsTitle({ title, subtitle }) {
    return (
        <>
            <div style={{textAlign:"center",width:'100%'}} className="sec-heading">
            <h2  className="sec__title"> {title} </h2>
                <p className="sec__meta">{subtitle}</p>
          
            </div>
        </>
    )
}
