import axios from "./axios"


export default class homeService{

    static async institution(){
        return await axios.get("institution/list");
    }

    static async skill(){
        return await axios.get("skill-ready/list");
    }

    static async job(){
        return await axios.get("job-ready/list");
    }
}