import React from 'react'
import { Col, Row } from 'react-bootstrap'

const WorkDescription = ({heading="",data=[]}) => {

  // const data=[
  //   "Design new products, write down requirements for their enhanced performance, as well as develop maintenance schedules and charts.",
  //   "Test equipment and machinery, solve operational problems, estimate time and cost of electrical and electronic products.",
  //   "Supervise the production of electrical and electronic equipments and machinery such as broadcast and telecommunication systems, electric motors, controls of machinery, lights and wiring in building complexes, vehicles, aircraft, radar and navigation systems.",
  //   "Utilize ferroelectric, nonlinear, photo-conductive, and thermoelectric properties of materials.",
  //   "Develop new applications of conductance of metallic & non-metallic materials to be used in components.",
  //   "Estimate material, construction, and labour costs, and project timescales",
  //   "Evaluate electrical systems, products, components, and applications by designing and conducting research programmes.",
  // ]
  return (
    <>
    <Row className='my-4'>
      <Col>
          <h2 style={{fontWeight:"600"}} className='mb-3'>
            {/* Work Description */}
            {heading}
            </h2>
          <ul className='font-medium career-detail-list'>
            {
              data.map((el,idx)=>(
                <li style={{fontWeight:"500",marginBottom:"5px",fontSize:"18px",color:"#3F3F3F"}} key={idx}>{el?.point}</li>
              ))
            }

          </ul>
         
      </Col>
    </Row>
    </>
  )
}

export default WorkDescription