import React from "react";

const MindlerWay = ({ setShowModal }) => {
  const data = [
    {
      heading: "Advanced Assessments & Best-Fit Matches",
      description: `These programs are carefully crafted to meet the specific
               demands of both tech and non-tech industries.We understand that
               different career paths required different skill sets and our
               curriculam reflects that`,
    },
    {
      heading: "Advanced Assessments & Best-Fit Matches",
      description: `These programs are carefully crafted to meet the specific
                  demands of both tech and non-tech industries.We understand that
                  different career paths required different skill sets and our
                  curriculam reflects that`,
    },
    {
      heading: "Advanced Assessments & Best-Fit Matches",
      description: `These programs are carefully crafted to meet the specific
                  demands of both tech and non-tech industries.We understand that
                  different career paths required different skill sets and our
                  curriculam reflects that`,
    },
  ];

  return (
    <>
      <section style={{ padding: "20px", backgroundColor: "#f5f5f5" }}>
        <div className="container-md p-md-5 mb-2">
          <div className="row text-center">
            <div
              style={{ textAlign: "center", width: "100%" }}
              className="sec-heading"
            >
              <h2 className="sec__title"> The Mindler Way </h2>
              <p className="sec__meta px-md-5 mx-md-5">
                {
                  "These programs are carefully crafted to meet the specific demands of both tech and non-tech industries.We understand that different career paths required different skill sets and our curriculam reflects that"
                }
              </p>
            </div>
          </div>
        </div>

        {data.map((el, idx) => {
          return (
            <div
              style={{ minHeight: "60vh" }}
              className="p-md-5 col-11  mx-auto"
              key={idx}
            >
              <div className={`d-flex flex-column ${idx%2===0 ? "flex-md-row":"flex-md-row-reverse"} flex-md-row justify-content-between align-items-center`}>
                <div
                  style={{ borderRadius: "20px 0px 20px 0px" }}
                  className="bg-white me-md-3 col-lg-4 h-100 shadow"
                >
                  <img
                    src="./images/Group 160.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="col-lg-5 col-md-6 mx-auto my-2 p-2">
                  <h2 className="h4" style={{ color: "#F2695C" }}>
                  {el?.heading}
                  </h2>
                  <p className="fw-bold">
                   {el?.description}
                  </p>
                  <button
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="theme-button my-4"
                    style={{ backgroundColor: "#F2695C" }}
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div style={{ minHeight: "60vh" }} className="p-md-5 col-11  mx-auto">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div
              style={{ borderRadius: "20px 0px 20px 0px" }}
              className="bg-white me-md-3 col-lg-4 h-100 shadow"
            >
              <img src="./images/Group 160.jpg" alt="" className="img-fluid" />
            </div>

            <div className="col-lg-5 col-md-6 mx-auto my-2 p-2">
              <h2 className="h4" style={{ color: "#F2695C" }}>
                Advanced Assessments & Best-Fit Matches
              </h2>
              <p className="fw-bold">
                These programs are carefully crafted to meet the specific
                demands of both tech and non-tech industries.We understand that
                different career paths required different skill sets and our
                curriculam reflects that
              </p>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="theme-button my-4"
                style={{ backgroundColor: "#F2695C" }}
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div>

        <div style={{ minHeight: "60vh" }} className=" p-md-5 ">
          <div className="d-flex flex-column flex-md-row-reverse justify-content-between align-items-center ">
            <div
              style={{ borderRadius: "20px 0px 20px 0px" }}
              className="bg-white border me-md-3 col-lg-4 h-100 container shadow"
            >
              <img src="./images/Group 160.jpg" alt="" className="img-fluid" />
            </div>

            <div className="col-lg-5 col-md-6 mx-auto my-2 p-2">
              <h2 className="h4" style={{ color: "#F2695C" }}>
                Advanced Assessments & Best-Fit Matches
              </h2>
              <p className="fw-bold">
                These programs are carefully crafted to meet the specific
                demands of both tech and non-tech industries.We understand that
                different career paths required different skill sets and our
                curriculam reflects that
              </p>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="theme-button my-4"
                style={{ backgroundColor: "#F2695C" }}
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div>

        <div style={{ minHeight: "60vh" }} className=" p-md-5">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center ">
            <div
              style={{ borderRadius: "20px 0px 20px 0px" }}
              className="bg-white border me-md-3 col-lg-4  container shadow"
            >
              <img src="./images/Group 160.jpg" alt="" className="img-fluid" />
            </div>

            <div className="col-md-6 mx-auto my-2 p-2">
              <h2 className="h4" style={{ color: "#F2695C" }}>
                Advanced Assessments & Best-Fit Matches
              </h2>
              <p className="fw-bold">
                These programs are carefully crafted to meet the specific
                demands of both tech and non-tech industries.We understand that
                different career paths required different skill sets and our
                curriculam reflects that
              </p>
              <button
                onClick={() => {
                  setShowModal(true);
                }}
                className="theme-button my-4"
                style={{ backgroundColor: "#F2695C" }}
              >
                Enquire Now
              </button>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default MindlerWay;
