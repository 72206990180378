import axios from "./axios";

export default class careerPathService{
    static async category(id){
        return await axios.get("career-path/list",{params:{parentId:id}})
    }

    static  async details(id){
        console.log("id",id)
        return await axios.get(`career-path/details/${id}`)
    }
}