import axios from "./axios";

export default class blogService{
    static async list(query){
        return await axios.get("blog/list",{params:query})
    }

    static  async detail(slug){
        return await axios.get(`blog/details/${slug}`)
    }
}