import axios from './axios'


export default class mentorService{
    static async list(query){
        return await axios.get("/mentor/list",{params:query})
    }

    static async save(formData){
        return await axios.post("mentor/save",formData)
    }
}