import React, { useEffect, useState } from "react";
import Navbar from "../components/common/Navbar";
import Footer from "../components/other/Footer";
import Breadcrumb from "../components/common/Breadcrumb";
import careerPathService from "../services/careerPath";
import { useHistory } from "react-router-dom";
// import { FaArrowRightLong } from "react-icons/fa6";

const CareerPath = () => {
  const [careerCategory, setCareerCategory] = useState([]);
  const [id, setId] = useState("");
  const [prevId,setPrevId]=useState([])
  const [title, setTitle] = useState([]);
  // const navigate=useNavigate()
  const history = useHistory();

  const handleCard = (item) => {
    setPrevId([...prevId,id])
    setId(item._id);
    setTitle([...title,item.title]);
    if (item.isDetailPage) {
      history.push("/career-path-detail/" + item._id);
    }
  };

  const handleBack=()=>{
    const prevIdsArray=[...prevId];
    const lastId=prevIdsArray.pop();
    const prevTitlesArray=[...title];
     prevTitlesArray.pop();
    setTitle(prevTitlesArray)
    setId(lastId);
    setPrevId(prevIdsArray);
  }

  useEffect(() => {
    careerPathService
      .category(id)
      .then((res) => {
        if (res.success) {
          setCareerCategory(res.data);
        }
      })
      .catch((err) => {
        throw err;
      });
  }, [id]);

  return (
    <div className="bg-light">
      {/* Header */}
      <header className="header-area">
        <Navbar />
      </header>

      {/* Breadcrumb */}
      <Breadcrumb title="Career Path" />

      <section className="container my-5">
        <div className="d-flex flex-column flex-lg-row  align-items-center">
        <div style={{ color: "#233D63" }} className="m-auto text-center my-3 col-11">
          <h1 style={{ fontWeight: "700" }} className="mb-3">
            {title[title.length-1] || "Career Options"} 
          </h1>
          <strong style={{ color: "#233D63", fontWeight: "600" }}>
            These programs are 100% industry-led and mentored by Industry
            Mentor. You can choose from full-length Tech and Non-tech Programs.
          </strong>
        </div>
        {id && 
        <button onClick={handleBack} style={{backgroundColor:"#F66B5D"}} className="back-btn theme-button d-flex justify-content-evenly align-items-center px-3 px-0 mx-auto   align-self-start rounded-full"><img width={25} height={25} className="me-3" src="./images/backArrow.svg" alt=""/> Back</button>
      }
        </div>
        <div className="row row-cols-md-2 row-cols-lg-3 g-5 pt-5 ">
          {careerCategory.map((item, index) => {
            return (
              <article role="button" aria-label="Clickable Card" className="bg-transparent" onClick={() => handleCard(item)} key={index}>
                <div className="card bg-white p-3 border-0 shadow rounded-4">
                  {
                    item?.img &&  
                    <img
                    src={item?.img}
                    height={219}
                    width={319}
                    style={{objectFit:"cover"}}
                    className="card-img rounded-3"
                    alt="..."
                  />
                  }
                 
                  <div className="card-body mb-4 text-center">
                    <h2 className="card-title fw-bold">{item?.title}</h2>
                    <h5 style={{height:"30px"}} className="card-text fw-semibold">
                     {item?.subTitle &&"("+item?.subTitle+")"}
                    </h5>
                  </div>
                  <button
                    style={{ backgroundColor: "rgb(242, 105, 92)",lineHeight:"0px" }}
                    className="theme-button col-6 m-auto career-card-btn"
                  >
                    <img
                    height={50}
                    width={50}
                      src="./images/right-arrow.svg"
                      alt=""
                      className=" text-white object-cover"
                    />
                  </button>
                </div>
              </article>
            );
          })}

          {/* <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>

        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>

          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>

          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div>


          
        <div>
          <div className="card bg-white p-0 border-0">
            <img src="https://minzel-react.vercel.app/static/media/img22.9a65f1dd.jpg" className=" img-fluid rounded-4" alt="..." />
            <div className="card-body mb-4">
              <h5 style={{color:"#F2695C"}} className="card-title fw-bold">Class 8 - 12</h5>
              <h6 className="card-text fw-semibold">
                Stream & Subject Selection
              </h6>
            </div>
          </div>
        </div> */}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default CareerPath;
