import React, { useEffect, useState }  from 'react'
import Navbar from '../components/common/Navbar'
import Breadcrumb from '../components/common/Breadcrumb'
import BlogDetailsContent from '../components/blog/BlogDetailsContent'
import Footer from '../components/other/Footer'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import blogService from '../services/blog'

export default function BlogDetailsPage() {
    const [data,setData]=useState(null)
    const params=useParams();

    useEffect(()=>{
        if(params && params.slug)
          blogService.detail(params?.slug)
        .then(res=>{
            if(res.success){
               setData(res.data)
            }
        })
        .catch(err=>{throw new Error(err)});
        
    },[])
    return (
        <>
            {/* Header */}
            <header className="header-area">
                <Navbar />
            </header>

            {/* Breadcrumb */}
            <Breadcrumb title={data?.title} />

            {/* Blog Details */}
            <BlogDetailsContent data={data} />

            {/* Footer */}
            <Footer />
        </>
    )
}
