import React,{useState,useEffect} from "react";
// import { BlogContext } from "../../context";
import BlogFeatures from "./BlogFeatures";
import blogService from "../../services/blog";


const Blog = ({ blog }) => {
  const [blogList,setBlogList]=useState([]);

  useEffect(()=>{
      (async()=>{
          try {
              const response=await blogService.list();
              if(response.success){
               setBlogList(response.data);
              }
          } catch (error) {
              throw new Error(error);
          }
        
  
      })();
  },[])

  return (
    <section className="blog-wrapper">
      <div className="container">
        <div className="blog-grid">
            {/* <BlogFeatures blog={blog}/> */}
            {blogList.filter((_,i)=>i<3).map((item, index) => {
                return <BlogFeatures key={index} blog={item} />;
            })}
        </div>
      </div>
    </section>
  );
};

export default Blog;

