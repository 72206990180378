import React, { useState } from "react";
import toast from "react-hot-toast";
import { FiChevronRight } from "react-icons/fi";
import subscription from "../../services/subscription";


export default function SubscribeForm() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email.trim() || !emailPattern.test(email.trim())) {
        setError("Please enter a valid email address.");
        return;
      }

      const res = await subscription.save({ email });
      if (res.success) {
        toast.success(res.message);
        setEmail("");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  return (
    <div className="contact-form-subscribe-content">
      <form>
        <div className="d-flex align-items-start">
            <div className="col-9">
            <input
            type="email"
            name="email"
            placeholder="Enter your email..."
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError("");
            }}
          />
          {error && <p className="text-danger text-start">{error}</p>}
            </div>
         
          <button type="submit" className="theme-button" onClick={handleSubmit}>
            Subscribe <FiChevronRight className="icon" />
          </button>
        </div>
      </form>
    </div>
  );
}
